import React, { useEffect } from 'react'
import MobileFeature from '../components/homecomponents/MobileFeature'
import AddNauha from '../components/homecomponents/AddNauha'
import HeroSection from '../components/homecomponents/HeroSection'
import CategorySection from '../components/homecomponents/CategorySection'
import RecentlyAddedCard from "../components/homecomponents/RecentlyAddedCard"
import { Helmet } from 'react-helmet-async'
import HalfBackground from '../components/homecomponents/HalfBackground'
import UniqueAppScreen from '../components/homecomponents/UniqueAppScreen'
import Testimnonials from '../components/homecomponents/Testimnonials'
import CheckDevice from '../components/common/CheckDevice'
import RecentlyAddedSection from '../components/homecomponents/RecentlyAddedSection/RecentlyAddedSection'
import DonateHero from '../components/homecomponents/DonateNow'
import Footer from '../components/common/Footer'
import Playstore from '../components/homecomponents/Playstore'
import useScrollToTop from '../hooks/useScrollToTop'
import CategorySectionDesktop from "../components/homecomponents/CategorySectionDesktop"

const Home = () => {
  useScrollToTop()
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title className="">Bayaaz - Home</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content={"description"}></meta>
      </Helmet>
      < CheckDevice>
        <HeroSection />
        <CategorySectionDesktop/>
        <MobileFeature />
      </CheckDevice>
      <CategorySection />
      {/* <AddNauha /> */}
      <RecentlyAddedSection />
      <CheckDevice>
      <UniqueAppScreen />
      {/* <DonateHero /> */}
      <HalfBackground />
      <Playstore/>
      <Footer />
      </CheckDevice>
 
      {/* <Testimnonials/> */}



    </>
  )
}

export default Home