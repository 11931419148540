import React from 'react'

const Notifications = () => {
  return (
    <>
      <div className="header p-4 px-6 flex items-center font-medium text-primary bg-secondary">
        <i className="bi bi-bell-fill text-lg mr-4"></i>
        <h1 className='text-lg'>Notification</h1>
        <button className='ml-auto'>
          {/* <i className="bi bi-x-lg "></i> */}
        </button>
      </div>
      <div className="container font-medium mx-auto px-5">
        {/* <div className='w-full flex bg-red-700 text-white rounded-lg my-4  p-4 '>
          <h1>Wiladaat Mubarak 15th Shabaan</h1>
          <i className="bi bi-x-lg ml-auto"></i>
        </div> */}
        <div className='w-full flex bg-red-700 text-white rounded-lg my-4  p-4 '>
          <h1 className='text-ellipsis overflow-hidden whitespace-normal'>Our App is Under Development You Might Face Problem</h1>
          {/* <i className="bi bi-x-lg ml-auto"></i> */}
        </div>

      </div>
    </>
  )
}

export default Notifications