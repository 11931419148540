import React, { useState } from 'react';
import useScrollToTop from '../hooks/useScrollToTop';
import Footer from '../components/common/Footer';

const PrivacyPolicy = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useScrollToTop(true)
  return (
    <>
    <div className="container mx-auto mt-8">
      <div className="flex justify-center select-none border gap-2 rounded-lg p-2 w-fit mx-auto">
        <div
          className={`cursor-pointer py-2 px-4 text-lg rounded-lg font-semibold transition-colors duration-300 ${
            activeTab === 1 ? 'bg-primary text-secondary' : 'hover:bg-secondary'
          }`}
          onClick={() => handleTabClick(1)}
        >
          Privacy Policy
        </div>
        <div
          className={`cursor-pointer py-2 px-4 text-lg rounded-lg font-semibold transition-colors duration-300 ${
            activeTab === 2 ? 'bg-primary text-secondary' : 'hover:bg-secondary'
          }`}
          onClick={() => handleTabClick(2)}
        >
          Terms & Conditions
        </div>
      </div>
      <div className="mt-4 p-4 border-t">
        {activeTab === 1 && <PrivacyContent/>}
        {activeTab === 2 && <TermsAndCondition/>}
      </div>
    </div>

    <Footer/>
  </>
  
  
  )
}

export function PrivacyContent(){
  return(
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
    <strong className="text-3xl mb-4 block">Privacy Policy</strong>
    <p className="text-gray-700 mb-4">
      Sayed 12M built the Bayaaz app as a Free app. This SERVICE is provided by
      Sayed 12M at no cost and is intended for use as is.
    </p>
    <p className="text-gray-700 mb-4">
      This page is used to inform visitors regarding my policies with the collection,
      use, and disclosure of Personal Information if anyone decided to use my Service.
    </p>
    <p className="text-gray-700 mb-4">
      If you choose to use my Service, then you agree to the collection and use of
      information in relation to this policy. The Personal Information that I collect is
      used for providing and improving the Service. I will not use or share your
      information with anyone except as described in this Privacy Policy.
    </p>
    <p className="text-gray-700 mb-4">
      The terms used in this Privacy Policy have the same meanings as in our Terms
      and Conditions, which are accessible at Bayaaz unless otherwise defined in this Privacy Policy.
    </p>
    <p className="text-gray-700 my-4"><strong className='text-2xl'>Information Collection and Use</strong></p>
    <p className="text-gray-700 mb-4">
      For a better experience, while using our Service, I may require you to provide
      us with certain personally identifiable information, including but not limited to
      We use your data to provide and improve the Service, We may also collect information
      on how the Service is accessed and used ("Usage Data"). The information that
      I request will be retained on your device and is not collected by me in any way.
    </p>
    <div className="mt-4">
      <p className="text-gray-700 mb-2">
        The app does use third-party services that may collect information used to identify you.
      </p>
      <p className="text-gray-700 mb-2">
        Link to the privacy policy of third-party service providers used by the app:
      </p>
      <ul className="list-disc ml-6">
        <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer" className="text-blue-500">Google Play Services</a></li>
        {/* Add more third-party services as needed */}
      </ul>
    </div>
    {/* Add more paragraphs as needed */}
    <p className="text-gray-700 my-4"><strong className='text-2xl'>Log Data</strong></p>
    <p className="text-gray-700 mb-4">
      I want to inform you that whenever you use my Service, in a case of an error
      in the app, I collect data and information (through third-party products) on your
      phone called Log Data. This Log Data may include information such as your device
      Internet Protocol (“IP”) address, device name, operating system version,
      the configuration of the app when utilizing my Service, the time and date of your
      use of the Service, and other statistics.
    </p>
    {/* Add more paragraphs as needed */}
    <p className="text-gray-700 my-4"><strong className='text-2xl'>Cookies</strong></p>
    <p className="text-gray-700 mb-4">
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the websites
      that you visit and are stored on your device's internal memory.
    </p>
    <p className="text-gray-700 mb-4">
      This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
    </p>
    <p className="text-gray-700 my-4"><strong className='text-2xl'>Service Providers</strong></p>

<p className="text-gray-700 mb-4">
  Sayed 12M may employ third-party companies and individuals for the following reasons:
</p>

<ul className="list-disc ml-6 mb-4">
  <li>Facilitate our Service;</li>
  <li>Provide the Service on our behalf;</li>
  <li>Perform Service-related services; or</li>
  <li>Assist us in analyzing how our Service is used.</li>
</ul>

<p className="text-gray-700 mb-4">
  Users of this Service should be aware that these third parties have access to their Personal
  Information. This access is granted to perform tasks assigned to them on our behalf. However,
  they are obligated not to disclose or use the information for any other purpose.
</p>

<p className="text-gray-700 my-4"><strong className='text-2xl'>Security</strong></p>
<p className="text-gray-700 mb-4">
  Sayed 12M values your trust in providing personal information and strives to use
  commercially acceptable means to protect it. However, it's important to note that no method
  of transmission over the internet, or method of electronic storage, is 100% secure and reliable.
  Absolute security cannot be guaranteed.
</p>

<p className="text-gray-700 my-4"><strong className='text-2xl'>Links to Other Sites</strong></p>
<p className="text-gray-700 mb-4">
  This Service may contain links to other sites. Clicking on a third-party link will direct you to that site.
  Note that these external sites are not operated by Sayed 12M. It is strongly advised to review the
  Privacy Policy of these websites, as Sayed 12M has no control over and assumes no responsibility
  for the content, privacy policies, or practices of any third-party sites or services.
</p>

<p className="text-gray-700 my-4"><strong className='text-2xl'>Children’s Privacy</strong></p>
<div className="text-gray-700 mb-4">
  <p>
    These Services do not address anyone under the age of 13. Sayed 12M does not knowingly
    collect personally identifiable information from children under 13 years of age.
  </p>
  <p>
    In the event that a child under 13 has provided personal information, it will be immediately
    deleted from our servers. If you are a parent or guardian and are aware that your child has
    provided personal information, please contact Sayed 12M to take the necessary actions.
  </p>
</div>

<p className="text-gray-700 my-4"><strong className='text-2xl'>Changes to This Privacy Policy</strong></p>
<p className="text-gray-700 mb-4">
  Sayed 12M may update the Privacy Policy from time to time. Users are advised to review this
  page periodically for any changes. Notification of changes will be made by posting the new
  Privacy Policy on this page.
</p>

<p className="text-gray-700 mb-4">This policy is effective as of 2024-02-06</p>

<p className="text-gray-700 my-4"><strong className='text-2xl'>Contact Us</strong></p>
<p className="text-gray-700 mb-4">
  If you have any questions or suggestions about the Privacy Policy, do not hesitate to
  contact Sayed 12M at <a href="mailto:sayed12m.official@gmail.com" className="text-blue-500">sayed12m.official@gmail.com</a>.
</p>
</div>

    
  )
}

export function TermsAndCondition(){
  return(
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
    <strong className="text-3xl mb-4 block">Terms & Conditions</strong>
    <p className="text-gray-700 mb-4">
      By downloading or using the app, these terms will automatically apply to
      you – please read them carefully before using the app. You’re not allowed
      to copy or modify the app, any part of the app, or our trademarks in any way.
      Extracting the source code or translating the app into other languages is
      strictly prohibited.
    </p>
    <p className="text-gray-700 mb-4">
      Sayed 12M is committed to ensuring that the app is as useful and efficient
      as possible. We reserve the right to make changes or charge for services at any time.
      We will always make it clear before charging you for the app or its services.
    </p>
    <p className="text-gray-700 mb-4">
      The Bayaaz app processes personal data to provide the service. Keep your phone
      secure, and avoid jailbreaking or rooting your device, as it may compromise
      security and affect the app's functionality.
    </p>
    <div className="mt-4">
      <p className="text-gray-700 mb-2">
        The app uses third-party services with their own Terms and Conditions.
      </p>
      <ul className="list-disc ml-6">
        <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer" className="text-blue-500">Google Play Services</a></li>
        {/* Add more third-party services as needed */}
      </ul>
    </div>
   
    <p className="text-gray-700 mt-4">
    You should be aware that there are certain things that Sayed 12M will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but Sayed 12M cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
    </p>
    <p className="text-gray-700 mt-4">
    If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third-party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.
</p>
<p className="text-gray-700 mt-4">
Along the same lines, Sayed 12M cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, Sayed 12M cannot accept responsibility.
</p>
<p className="text-gray-700 mt-4">
With respect to Sayed 12M’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Sayed 12M accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
</p>
<p className="text-gray-700 mt-4">
At some point, we may wish to update the app. The app is currently available on Android & iOS – the requirements for the both systems(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. Sayed 12M does not promise that it will always update the app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.
</p>
    {/* Add more paragraphs as needed */}
    <p className="text-gray-700 my-4">
      <strong className='text-2xl'>Changes to This Terms and Conditions</strong>
    </p>
    <p className="text-gray-700 mt-2">
      I may update our Terms and Conditions from time to time. Review this page periodically
      for any changes. I will notify you of changes by posting the new Terms and Conditions on this page.
    </p>
    <p className="text-gray-700 my-4">
      <strong className='text-2xl'>Contact Us</strong>
    </p>
    <p className="text-gray-700 mt-2">
      If you have any questions or suggestions about my Terms and Conditions, do not
      hesitate to contact me at <a href="mailto:sayed12m.official@gmail.com" className="text-blue-500">sayed12m.official@gmail.com</a>.
    </p>
  </div>
  )
}
export default PrivacyPolicy