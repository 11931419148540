import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectRouteMobile = ({ children }) => {
    return  (
        <>
          {
              window.innerWidth < 768 ? children : <Navigate to={"/"} replace/>

          } 
        </>
    )
}

export default ProtectRouteMobile