import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Login = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please enter a valid email address.').email('Invalid email address'),
      password: Yup.string().matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must be at least 8 characters and include at 1 special character."
      )
    }),
    onSubmit: (values) => {
      console.log('Form submitted successfully:');
    },
  });

  return (
    <div className="flex w-full justify-center my-10 h-full">
      <div className="md:w-[50%] w-full p-4 px-8 rounded-md shadow-lg sm:p-8 h-fit bg-white text-primary">
        <h2 className="my-5 font-semibold text-center text-3xl">Welcome Back</h2>
        <p className="text-sm text-center dark:text-gray-400">
          Don't have an account? 
          <Link to="/signup" rel="noopener noreferrer" className="underline ml-1">
            Sign up here
          </Link>
        </p>
        <div className="my-6">
          <button
            aria-label="Login with Google"
            type="button"
            className="flex items-center justify-center w-full p-2 space-x-2 border rounded-md"
          >
            {/* Google Icon SVG here */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              className="w-7 h-7 fill-current"
              viewBox="0 0 48 48"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              ></path>
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              ></path>
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
            </svg>
            <p className="font-semibold text-lg">Login with Google</p>
          </button>
        </div>
        <div className="flex items-center w-full my-4">
          <hr className="w-full dark:text-gray-400" />
          <p className="px-3 text-primary">OR</p>
          <hr className="w-full dark:text-gray-400" />
        </div>
        <form onSubmit={formik.handleSubmit} className="space-y-8">
          <div className="space-y-4">
            <div className="relative mb-7 mt-10">
              <input
                type="text"
                id="email"
                name="email"
                {...formik.getFieldProps('email')}
                className={`block px-2.5 py-2.5  w-full text-md text-primary bg-transparent border-1 border ${
                  formik.errors.email && formik.touched.email ? 'border-red-500' : 'border-gray-400'
                } appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-primary peer`}
                placeholder="Enter Your Email Address"
              />
              {formik.errors.email && formik.touched.email && (
                <p className="text-red-500 absolute left-0 mt-1">
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className="relative mb-7 mt-10">
              <input
                type="password"
                id="password"
                name="password"
                {...formik.getFieldProps('password')}
                className={`block px-2.5 py-2.5 w-full text-md text-primary bg-transparent border-1 border ${
                  formik.errors.password && formik.touched.password ? 'border-red-500' : 'border-gray-400'
                } appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-primary peer`}
                placeholder="Enter Your Password"
              />
              {formik.errors.password && formik.touched.password && (
                <p className="text-red-500 absolute left-0 mt-1">
                  {formik.errors.password}
                </p>
              )}
            </div>
          </div>
          
          <button
            type="submit"
            className="uppercase w-full py-3 tracking-wider text-md rounded-md cursor-pointer font-semibold bg-primary text-secondary outline-none"
          >
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
