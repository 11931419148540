import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MyBayaaz = () => {
  const [savedData, setSavedData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    const savedDataFromLocalStorage = JSON.parse(localStorage.getItem('savedData')) || [];
    setSavedData(savedDataFromLocalStorage);
  }, []);

  const handleDelete = (index) => {
    const updatedSavedData = savedData.filter((_, i) => i !== index);
    localStorage.setItem('savedData', JSON.stringify(updatedSavedData));

    setSavedData(updatedSavedData);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <>
      <section className="h-full w-full">
        <div className="container mx-auto flex flex-col md:flex-row h-full w-full">
          <div className="flex md:w-1/2 flex-col items-center pb-2 md:p-6">
            <div className="flex flex-col justify-center px-5">
              <p className="text-center py-5">You can Add Your Own Personal Lyrics or Unpublished Lyrics which are Available Offline and Privately Here.</p>
              <Link to="/mybayaaz/addnauha" className="p-2 flex font-medium justify-center w-full text-lg items-center bg-primary text-secondary rounded-lg transition-colors duration-300 ease-in-out">
                <span className="mr-2">+</span> Add Your Lyrics
              </Link>
            </div>
          </div>
          <div className="md:w-1/2 my-2 h-full md:rounded-lg border">
            <div className="flex text-black py-2 md:rounded-t-lg font-medium w-full bg-gray-100 items-center">
              <h1 className="text-lg ml-5 p-2">Your Lyrics</h1>
              <select
                className="ml-auto mr-5 px-4 py-2 accent-primary rounded-lg bg-white border-none outline-none border my-2"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="All">All</option>
                <option value="nauha">Nauha</option>
                <option value="manqabat">Manqabat</option>
                <option value="soz">Soz</option>
                <option value="shayari">Shayari</option>
              </select>
            </div>
            <div className="h-full w-full">
              <ul className="px-5">
                {savedData.length > 0 ? (
                  savedData
                    .filter(item => selectedCategory === "All" || item.category === selectedCategory)
                    .map((item, index) => (
                      <li key={index} className="border-b border-gray-200 py-4">
                        <h4 className="text-2xl font-medium">{item.title}</h4>
                        <p className="text-sm text-primary w-fit bg-secondary px-4 rounded-lg py-2">{item.category}</p>
                        <p className="text-lg">{item.lyrics}</p>
                        <button className="bg-red-500 text-white px-2 py-1 rounded mt-2" onClick={() => handleDelete(index)}><i class="bi bi-trash-fill"></i> Delete</button>
                      </li>
                    ))
                ) : (
                  <li className="border-b border-gray-200 py-4">
                    <span className="text-lg font-medium">No Record Found</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyBayaaz;
