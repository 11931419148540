import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const AddNauha = () => {
    const [savedData, setSavedData] = useState([]);

    useEffect(() => {
        const savedDataFromLocalStorage = JSON.parse(localStorage.getItem('savedData')) || [];
        setSavedData(savedDataFromLocalStorage);
    }, []);

    const [formData, setFormData] = useState({
        title: '',
        category: 'Select Type',
        lyrics: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        if (formData.title && formData.category !== 'Select Type' && formData.lyrics) {
            // Save to local storage
            const updatedSavedData = [...savedData, formData];
            localStorage.setItem('savedData', JSON.stringify(updatedSavedData));

            // Save to MongoDB
            axios.post('YOUR_BACKEND_API_ENDPOINT', formData)
                .then(response => {
                    console.log('Data saved to MongoDB:', response.data);
                })
                .catch(error => {
                    console.error('Error saving data to MongoDB:', error);
                });

            // Clear form data
            setFormData({
                title: '',
                category: 'Select Type',
                lyrics: '',
            });

            toast.success("Saved Successfully")

            // Update savedData state
            setSavedData(updatedSavedData);
        } else {
            alert('Please fill in all fields');
        }
    };


    return (
        <div className="mx-auto bg-white rounded-md pb-12">
            <h2 className="text-lg rounded-br-2xl font-bold mb-4 px-6 text-primary py-4 bg-secondary">Add Your Lyrics</h2>
            <div className="px-5">
                <div className="mb-4">
                    <label htmlFor="title" className="block text-lg font-medium text-primary">Title</label>
                    <input type="text" className="w-full p-2 border rounded-md mb-5 focus:border-primary outline-none" name="title" id="title" value={formData.title} onChange={handleChange} />
                    <label htmlFor="category" className="block text-lg text-primary font-medium">Category</label>
                    <select id="category" name="category" className="mt-1 p-2 border rounded-md focus:border-primary outline-none w-full" value={formData.category} onChange={handleChange}>
                        <option disabled>Select Type</option>
                        <option value="nauha">Nauha</option>
                        <option value="manqabat">Manqabat</option>
                        <option value="soz">Soz</option>
                        <option value="shayari">Shayari</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="lyrics" className="block text-lg font-medium text-primary">Lyrics</label>
                    <textarea id="lyrics" name="lyrics" rows="12" className="mt-1 resize h-full p-2 border rounded-md w-full focus:border-primary outline-none" value={formData.lyrics} onChange={handleChange}></textarea>
                </div>
                <button className="bg-primary bg-opacity-70 hover:bg-primary hover:bg-opacity-100 text-white font-semibold w-full py-3 px-4 rounded transition duration-300 ease-in-out" onClick={handleSubmit}>
                    <i className="bi bi-save mr-3"></i>Save Lyrics
                </button>
            </div>
            <div>
            </div>
        </div>
    );
};

export default AddNauha;
