import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/common/Footer"
import DonateUs from "./DonateUs";
import useScrollToTop from '../hooks/useScrollToTop'
import useDeviceType from "../hooks/useDeviceType";
export default function AboutUs() {
  useScrollToTop()
  return (
    <div className="pb-6">
      <AboutApp />
      <AboutHiring />
      {/* <AboutTeam/> */}
      {/* <DonateUs /> */}
      <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contributors</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
        We are Thankful to Our Members Who Made this app possible by submitting Nauha and helping with correction.
      </p>
    </div>
    <div className="flex flex-wrap -m-2">
      <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg bg-secondary text-primary">
          <img alt="contributor" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={require("../assets/images/ruhul.png")}/>
          <div className="flex-grow">
            <h2 className="text-gray-900 title-font font-medium">Ruhul Mirza</h2>
            <p className="text-gray-500">(Full Stack Developer)</p>
          </div>
        </div>
      </div>
      <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg bg-secondary text-primary">
          <img alt="contributor" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={require("../assets/images/aliraza.png")}/>
          <div className="flex-grow">
            <h2 className="text-gray-900 title-font font-medium">Ali Raza Shaikh</h2>
            <p className="text-gray-500">(Graphic Designer)</p>
          </div>
        </div>
      </div>
      <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg bg-secondary text-primary">
          <img alt="contributor" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/84x84"/>
          <div className="flex-grow">
            <h2 className="text-gray-900 title-font font-medium">Be The Next Contributor - Email On</h2>
            <p className="text-gray-500"> bayaaz.official@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <Footer />
    </div>
  );
}
export function AboutApp() {
  return (
    <div className="bg-gray-100 pb-10">
      {/* Header */}
      <header className="text-center px-4 py-12 bg-gradient-to-b from-primary to-secondary text-primary">
        <h1 className="text-4xl font-bold">Welcome to BAYAAZ</h1>
        <p className="text-lg mt-3">An Azadari App Experience Like Never Before</p>
      </header>

      {/* Main Content */}
      <div className="container mx-auto mb-6 p-8 bg-white shadow-lg rounded-md">
        <p className="text-lg mb-6">
          <h1 className="text-center my-2">
            In The Name Of Allah, The Most Gracious And The Most Merciful
          </h1>
          Bayaaz,an Azadari app. It was created by <strong className="uppercase">Sayed 12M</strong> with the blessing of 14 Masoomeen Zere Sarparasti : Imam-e-Zamana (A.T.F.S). In this app, we have included the lyrics of almost all the Nauhakhwans, including Nauhas, Soz, and Manqabat. Additionally, we have introduced a unique feature for Shayari, allowing users to contribute their own poetry that will be visible to everyone.
        </p>

        <p className="text-lg mb-6">
          Furthermore, the app includes content related to Anjuman (community gatherings). There will be specific Nauhas announced in the app, as well as announcements about Anjuman events, specifying when and where the programs are scheduled.
        </p>
        <p className="text-lg mb-6">
          The concept of Anjuman is, Insha'Allah,<strong className="uppercase"> coming soon</strong>, enhancing the community experience by providing information about ongoing events and facilitating community engagement and participation.
        </p>
      </div>


    </div>
  )
}

export function AboutTeam() {
  return (
    <div className="py-10 bg-gray-50">
      <div className="container mx-auto px-6 md:px-12 xl:px-32">
        <div className="mb-16 text-center">
          <h2 className="mb-16 text-center text-4xl text-primary font-bold md:text-5xl">Our Team</h2>

        </div>
        <div className="grid gap-12 items-center md:grid-cols-3">
          <div className="space-y-4 text-center">
            <img className="w-64 h-64 mx-auto rounded-xlmd:w-40 md:h-40 lg:w-64 lg:h-64"
              src="https://cdn-icons-png.flaticon.com/512/21/21104.png" alt="woman" loading="lazy" width="640" height="805" />
            <div>
              <h4 className="text-2xl text-primary mb-2">Mirza Ruhul</h4>
              <span className="block text-sm text-gray-500">Front-End Developer</span>
            </div>
          </div>
          <div className="space-y-4 text-center">
            <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80"
              src="https://cdn-icons-png.flaticon.com/512/21/21104.png" alt="man" loading="lazy" width="1000" height="667" />
            <div>
              <h4 className="text-2xl text-primary mb-2">Sayed Hasan Mehdi</h4>
              <span className="block text-sm text-gray-500">Full-Stack Developer</span>
            </div>
          </div>
          <div className="space-y-4 text-center">
            <img className="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
              src="https://cdn-icons-png.flaticon.com/512/21/21104.png" alt="woman" loading="lazy" width="1000" height="667" />
            <div>
              <h4 className="text-2xl text-primary mb-2">Ali Raza Shaikh</h4>
              <span className="block text-sm text-gray-500">Graphic Designer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export function AboutHiring() {

  return (
    <div className="container mx-auto pb-20">
      <div className="h-full flex items-center justify-center">
        <div className="w-full mx-auto p-8 bg-white shadow-lg rounded-md mt-10">
          <h2 className="text-3xl text-primary font-bold mb-6">
            Join Our Visionary Team for the Future
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-gray-200 p-4 rounded-lg cursor-pointer group duration-300 text-center transition transform hover:scale-105">
              <i className="bi bi-paint-bucket group-hover:text-primary text-4xl mb-4"></i>
              <p className="font-bold mt-3 group-hover:text-primary">
                Graphic Designer
              </p>
            </div>

            <div className="bg-gray-200 p-4 rounded-lg cursor-pointer group duration-300 text-center transition transform hover:scale-105">
              <i className="bi bi-laptop group-hover:text-primary  text-4xl mb-4"></i>
              <p className="font-bold mt-3 group-hover:text-primary">
                Front-end Developer
              </p>
            </div>

            <div className="bg-gray-200 p-4 rounded-lg cursor-pointer group duration-300 text-center transition transform hover:scale-105">
              <i className="bi bi-database group-hover:text-primary text-4xl mb-4"></i>
              <p className="font-bold mt-3 group-hover:text-primary">
                Backend Developer
              </p>
            </div>

            <div className="bg-gray-200 p-4 rounded-lg cursor-pointer group duration-300 text-center transition transform hover:scale-105">
              <i className="bi bi-journal-text group-hover:text-primary text-4xl mb-4"></i>
              <p className="font-bold mt-3 group-hover:text-primary">
                Data Entry Specialist
              </p>
            </div>
          </div>

          <p className="mt-8 text-lg text-gray-700">
            At Bayaaz, we operate on a unique principle -{" "}
            <span className="font-bold text-primary">
              we're not offering monetary compensation at this stage.
            </span>{" "}
            Instead, we invite you to join us because you believe in our vision
            and want to contribute from the heart. While financial rewards will
            come as we grow, our primary focus is on creating something
            extraordinary together.
          </p>

          <div className="mt-8">
            <div
              className="bg-primary w-full md:w-fit hover:opacity-80 text-white font-bold py-2 px-4 rounded focus:outline-none"
            >
             For Joining Us - Email On bayaaz.official@gmail.com
            </div>
          </div>

          <p className="mt-8 text-lg text-gray-700">
            We embrace innovation, and our tech stack includes cutting-edge
            technologies. We work with the sleek and powerful Tailwind CSS to
            make our designs not just functional but aesthetically pleasing.
          </p>

          <p className="mt-8 text-lg text-gray-700">
            If you're excited about the future and want to be part of a team
            that values creativity, collaboration, and a shared purpose, reach
            out to us. Let's build something beautiful together!
          </p>
        </div>
      </div>
    </div>
  );
}
