import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const useDisableF12 = () => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'F12') {
        e.preventDefault();
        toast.remove()
        toast((t) => (
          <span className='w-full'>
           Developer Mode <b>Disable</b>
            <Link className='ml-4 p-2 bg-primary text-secondary rounded-lg hover:bg-secondary hover:text-primary transition-colors duration-300' to={"/contactus"} onClick={() => toast.dismiss(t.id)}>
              Contact Us
            </Link>
          </span>
        ),{
          style: {
            border: '1px solid #603710',
            padding: '16px',
            color: '#603710',
          },
          iconTheme: {
            primary: '#603710',
            secondary: '#FFFAEE',
          },
        });
      };
      
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useDisableF12;
