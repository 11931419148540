import React from 'react'
import Footer from '../components/common/Footer'

const Shayari = () => {
  return (
    <>
      <div className='w-screen flex items-center justify-center h-full bg-gray-100'>
      <h1 className='text-4xl'>Coming Soon..</h1>
      </div>
      <Footer />
    </>
  )
}

export default Shayari