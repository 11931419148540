import React from "react";
import logo from "../../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion"

const Navbar = () => {
  return (
    <header className="bg-gray-50 sticky top-0 z-50 border-b">
      <div className="container p-3 md:p-0 mx-auto flex flex-wrap flex-row justify-between items-center">

        <a className="flex title-font font-medium items-center text-gray-900 md:mb-0">
          <img className="max-[300px]:h-8 max-[300px]:w-8 h-12 w-12" src={logo}></img>
          <span className="ml-3 text-xl text-primary max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm">Bayaaz</span>
        </a>

        <nav className=" hidden md:flex p-4 flex-wrap items-center gap-x-4 h-auto lg:text-base text-sm font-medium justify-center">
          <NavLink id="navlink" className={""} to={"/"}>Home</NavLink>
          <div className="relative group cursor-pointer">
            <span className="inline-flex p-4 justify-center items-center">
              Categories <svg xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" fill="black" className="h-3 w-3 ml-2 group-hover:rotate-0 -rotate-90 transition-transform duration-300" version="1.1" id="Layer_1" viewBox="0 0 330 330" xmlSpace="preserve"><path id="XMLID_225_"
                  d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /></svg></span>
            <ul className="absolute z-50 bg-transparent hidden text-primary transition-all duration-500 group-hover:block rounded-lg shadow-lg w-60">
              <div className="bg-transparent space"></div>
              <div className="bg-white rounded-lg border mt-4 p-2 px-5">
                <NavLink id="droplink" to={"/nauha"}><li className="py-2 px-3 my-2 hover:bg-secondary bg-white cursor-pointer transition-colors duration-300 rounded">Nauha</li></NavLink>
                <NavLink id="droplink" to={"/soz"}><li className="py-2 px-3 my-2 hover:bg-secondary bg-white cursor-pointer transition-colors duration-300 rounded">Soz</li></NavLink>
                <NavLink id="droplink" to={"/manqabat"}><li className="py-2 px-3 my-2 hover:bg-secondary bg-white cursor-pointer transition-colors duration-300 rounded">Manqabat</li></NavLink>

              </div>

            </ul>
          </div>

          <NavLink id="navlink" to={"/contactus"}>Contact Us</NavLink>
          <NavLink id="navlink" to={"/privacypolicy"}>Privacy Policy</NavLink>
        </nav>


        <div className="md:flex hidden lg:text-base text-sm">
          {/* <Link to="/signup" className="bg-primary text-secondary border-0 px-3 focus:outline-none py-1.5 rounded ml-auto md:ml-0 mr-2 md:mt-0">Sign Up
          </Link>
          <Link to="/login" className="text-primary border-primary border bg-gray-100 py-1.5 px-3 focus:outline-none hover:bg-gray-50 rounded ml-auto md:ml-0 md:mt-0">Login
          </Link> */}
          <motion.div
            whileTap={{ scale: 0.9 }}
          > <Link className="bg-primary py-2.5 px-4 rounded hover:scale-[0.977] transition-transform duration-300 text-secondary" to={"https://play.google.com/store/apps/details?id=bayaaz.com"} target="_blank"><i className="bi bi-google-play"></i> Download Now</Link></motion.div>
        </div>

        <div className="md:hidden flex ml-auto">
          {/* <Link>
          <i className="bi bi-send p-2 text-2xl max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm text-primary"></i>
          </Link> */}
          <Link to={"/notification"} className="relative">
            <span className="w-3 h-3 rounded-full bg-red-700 absolute right-2 top-0.5">

            </span>
            <i className="bi bi-bell p-2 text-2xl max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm text-primary"></i>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
