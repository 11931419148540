import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nauhaUrl: "https://bayaaz-backend-nauha.vercel.app/",
  manqabatUrl: "https://byaaz-backend-manqabat.vercel.app/",
  sozUrl: "https://byaaz-backend-soz.vercel.app/",
  shayariUrl: ""
};

const urlSlice = createSlice({
  name: "urls",
  initialState,
  reducers: {
    setNauhaUrl(state, action) {
      state.nauhaUrl = action.payload;
    },
    setManqabatUrl(state, action) {
      state.manqabatUrl = action.payload;
    },
    setShayariUrl(state, action) {
      state.shayariUrl = action.payload;
    },
    setSozUrl(state, action) {
      state.sozUrl = action.payload;
    }
  }
});

export const {setManqabatUrl,setNauhaUrl,setShayariUrl,setSozUrl} = urlSlice.actions;
export default urlSlice.reducer;
