import React from 'react'
import logo from "../../assets/images/recentlyadded.png"
import { Link } from 'react-router-dom'

const HalfBackground = () => {
    return (
        <div class="relative w-full">
            <div class="relative bg-secondary mt-48">
                <div class="container m-auto px-6 md:px-12 lg:px-7">
                    <div class="flex items-center flex-wrap px-2 md:px-0">
                        <div class="lg:ml-auto mx-auto lg:w-6/12 -mt-56">
                            <img src={logo} class="relative lg:mx-auto drop-shadow-sm shadow-black" alt="food illustration" loading="lazy"/>
                        </div>
                        <div class="relative lg:w-6/12 md:pb-12 lg:pb-0 lg:-mt-32">
                            <h1 class="font-bold text-4xl text-yellow-900 md:text-5xl lg:w-10/12">Do You Want to Add Lyrics ?</h1>
                            <p class="mt-8 text-gray-700 lg:w-10/12">Unleash Your Creativity: Design Your Bayaaz with Custom Lyrics</p>
                            <p className='mb-8'>Request It For Publish as You Want</p>
                            <Link className="bg-primary p-4 my-4 rounded hover:scale-[0.977] transition-transform duration-300 text-secondary" to={"https://play.google.com/store/apps/details?id=bayaaz.com"} target="_blank"><i className="bi bi-google-play"></i> Download Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default HalfBackground