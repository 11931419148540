import React, { useEffect, useState } from 'react';
import LyricsCard from '../components/common/LyricsCard';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { debounce } from 'lodash';
import { FixedSizeList as List } from 'react-window'; // Import FixedSizeList from react-window

const SearchLyrics = () => {
  const [searchInput, setSearchInput] = useState('');
  const location = useLocation();
  const { category } = useParams();
  const { pathname } = location;
  const { nauhaUrl, manqabatUrl, shayariUrl, sozUrl } = useSelector(state => state.urls);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');
  const [data, setData] = useState([]);

  const fetchLyrics = async () => {
    try {
      const { data } = await axios.get(`${url}search/lyrics/${searchInput}`);
      if (data) {
        setData(data.nauhas); // Assuming data is an array of nauhas
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchInput) fetchLyrics();
  }, [searchInput]);

  useEffect(() => {
    if (pathname.match("/nauha")) setUrl(nauhaUrl);
    if (pathname.match("/manqabat")) setUrl(manqabatUrl);
    if (pathname.match("/soz")) setUrl(sozUrl);
  }, []);

  const handleSearch = debounce((e) => {
    const trimmedValue = e.target.value.trim();
    setSearchInput(trimmedValue);
  }, 200);

  const Row = ({ index, style }) => {
    const item = data[index];
    return (
      <div style={style}>
        <LyricsCard
          category={category}
          recitername={item.recitername}
          year={item.year}
          title={item.title}
        />
      </div>
    );
  };

  return (
    <>
      <section className='overflow-auto'>
        <h1 className='my-4 px-2 text-lg sticky top-0 hidden md:block capitalize'>Search {category?category:pathname.replace("/","")}</h1>
        <div className="container px-2 mx-auto">
          <fieldset className="w-full my-4 sticky top-0 space-y-1 text-gray-800">
            <label htmlFor="Search" className="hidden">Search Lyrics</label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="button" title="search" className="p-1 focus:outline-none focus:ring">
                  <svg fill="currentColor" viewBox="0 0 512 512" className="w-4 h-4 text-gray-800">
                    <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
                  </svg>
                </button>
              </span>
              <input type="search" name="Search" onChange={handleSearch} placeholder={`Search`} className="w-full py-4 px-2 pl-10 capitalize text-sm rounded-md focus:outline-none bg-gray-100 text-gray-800 focus:bg-gray-50 focus:border-violet-600" />
            </div>
          </fieldset>
          {!loading && (
            <>
              <List
                height={400} // Set the height of the list
                itemCount={data.length}
                itemSize={100} // Adjust this value according to your card height
                autoWidth={true}// Set the width of the list
                className='w-full '
              >
                {Row}
              </List>
              <div className="flex justify-center items-center">
              <i className="bi bi-chevron-down animate-bounce"></i>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default SearchLyrics;
