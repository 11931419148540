// categoriesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash'; // Import lodash

// Thunks for fetching data for each category
export const fetchNauhaData = createAsyncThunk('categories/fetchNauhaData', async () => {
  const response = await axios.get('https://bayaaz-backend-nauha.vercel.app/get/reciter');
  const { reciternames } = response.data
  const sortedTitles = _.sortBy(reciternames, name => name.toLowerCase()); // Sort with lodash
  return sortedTitles;
});

export const fetchManqabatData = createAsyncThunk('categories/fetchManqabatData', async () => {
  const response = await axios.get('https://bayaaz-backend-manqabat.vercel.app/get/reciter');
  const { reciternames } = response.data
  const sortedTitles = _.sortBy(reciternames, name => name.toLowerCase()); // Sort with lodash
  return sortedTitles;
});

export const fetchSozData = createAsyncThunk('categories/fetchSozData', async () => {
  const response = await axios.get('https://bayaaz-backend-soz.vercel.app/get/reciter');
  const { reciternames } = response.data
  const sortedTitles = _.sortBy(reciternames, name => name.toLowerCase()); // Sort with lodash
  return sortedTitles;
});

export const fetchShayariData = createAsyncThunk('categories/fetchShayariData', async () => {
  const response = await axios.get('https://api.example.com/shayari');
  return response.data;
});

const initialState = {
  nauha: [],
  manqabat: [],
  soz: [],
  shayari: [],
  loading: false,
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    // Action creators for adding items to each category
    addNauha: (state, action) => {
      state.nauha.push(action.payload);
    },
    addManqabat: (state, action) => {
      state.manqabat.push(action.payload);
    },
    addSoz: (state, action) => {
      state.soz.push(action.payload);
    },
    addShayari: (state, action) => {
      state.shayari.push(action.payload);
    },
    // Action creators for handling loading and error states
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNauhaData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNauhaData.fulfilled, (state, action) => {
        state.loading = false;
        state.nauha = action.payload;
      })
      .addCase(fetchNauhaData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchManqabatData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchManqabatData.fulfilled, (state, action) => {
        state.loading = false;
        state.manqabat = action.payload;
      })
      .addCase(fetchManqabatData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSozData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSozData.fulfilled, (state, action) => {
        state.loading = false;
        state.soz = action.payload;
      })
      .addCase(fetchSozData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchShayariData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShayariData.fulfilled, (state, action) => {
        state.loading = false;
        state.shayari = action.payload;
      })
      .addCase(fetchShayariData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  addNauha,
  addManqabat,
  addSoz,
  addShayari,
  setLoading,
  setError,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
