import { useState, useLayoutEffect } from 'react';

function useDeviceType() {
  const [deviceType, setDeviceType] = useState('');

  useLayoutEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType('Mobile');
      } else if (width >= 768 && width < 1024) {
        setDeviceType('Tablet');
      } else {
        setDeviceType('Desktop');
      }
    }

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return deviceType;
}

export default useDeviceType;
