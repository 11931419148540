import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/demo.svg";

const HeroSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsError(true);
  };

  return (
    // <section className="md:px-2 w-full hidden md:flex justify-center container mx-auto pb-16">
    //   <div className="w-ful flex justify-center items-center">
    //     <div className="md:w-4/6 p-8">
    //       <span className='font-medium'>Increased Performance</span>
    //       <h2 className="uppercase my-4 border-l-2 border-primary px-2 text-primary text-4xl font-semibold">
    //         Your Digital Bayaaz Is About To Take Off
    //       </h2>
    //       <p className='text-black py-2 my-2'>An application developed for the Reciters to have almost every lyrics of Nauhas, Marsiyas and Manqabats Shayari easily in hand.</p>
    //       <button className="px-10 rounded-br-3xl bg-primary text-white font-semibold p-3 uppercase border-primary border hover:cursor-pointer">
    //         About Us
    //       </button>
    //     </div>
    //     <div className={`md:w-1/2 h-full md:justify-center ${isLoading?"p-5":""}`}>
    //       {
    //         isLoading ?
    //           <div className="w-full h-4/5 m-5 rounded-2xl bg-gray-300 animate-pulse"></div> : null
    //       }
    //       <img src={logo} onLoad={handleImageLoad}
    //         onError={handleImageError} alt="" />
    //     </div>
    //   </div>
    // </section>
    <section className="bg-white section h-[90vh] hidden flex-col justify-center items-center md:flex dark:bg-gray-900">
      <div className=" bg-primary py-2 px-5 flex justify-center  items-center cursor-pointer text-white rounded mb-5">
        <span className="font-medium tracking-wider ">
          Increased Performance
        </span>
        <i className="bi bi-rocket-takeoff ml-2 text-lg"></i>
      </div>
      <div className="mt-3">
        <h2 data-aos="fade-left" data-aos-duration={1500} className="my-4 tracking-wider font-bold text-center border-primary px-2 text-primary text-6xl">
          Your Digital Bayaaz Is
        </h2>
        <h2 data-aos="fade-right" data-aos-duration={1500} className="mb-5 tracking-wider font-bold text-center border-primary px-2 text-primary text-6xl">
          About To Take Off
        </h2>
      </div>
      <div className=" py-2 my-2">
        <p className="text-primary w-3/4 mx-auto text-center text-xl">
          Your application is a versatile platform that serves the needs of both
          reciters and the general audience. It provides a wide range of lyrics
          for nauhas, marsiyas, and manqabats, while also offering updates on
          majlis, matam, and jashan events for a diverse user base.
        </p>
      </div>
      <div className="flex gap-x-2">
        <Link to={"/aboutus"}>
          <button className="px-10 opacity-70 border-none outline-none tracking-wider mt-4 rounded bg-primary text-white font-semibold p-3 uppercase border-primary border hover:opacity-100 hover:cursor-pointer">
            About Us
          </button>
        </Link>
        <Link target="_blank" to={"https://play.google.com/store/apps/details?id=bayaaz.com"}>
          <button className="px-10 opacity-70 border-none outline-none tracking-wider mt-4 rounded bg-primary text-white font-semibold p-3 uppercase border-primary border hover:opacity-100 hover:cursor-pointer">
          <i className="bi bi-google-play"></i>  Download App
          </button>
        </Link>
      </div>
      {/* <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img loading='lazy' src={logo} alt="mockup" />
        </div> */}
    </section>
  );
};

export default HeroSection;
