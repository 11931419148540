import React from 'react'
import { useLocation } from 'react-router-dom';

const CheckBottomSheet = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    return (
        <>
            {pathname == "/privacypolicy" ? "" : children}
        </>
    )
}

export default CheckBottomSheet