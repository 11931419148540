import Sheet from 'react-modal-sheet';
import { useEffect, useState } from 'react';
import Bottomsheet from "./BottomSheet"
import { useDispatch, useSelector } from 'react-redux';
import { togglebottomsheet } from '../../redux/reducers/toggleSlice';
import { useLocation } from 'react-router-dom';

export default function BottomSheetMain() {
  const { bottomsheet } = useSelector((state) => state.toggle)
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location

  const handleclose = () => {
    dispatch(togglebottomsheet(false))
  }
  
  useEffect(()=>{
    dispatch(togglebottomsheet(false))
  },[pathname])

  return (
    <>
      <Sheet className='' style={{ zIndex: 1 }}  
      detent='content-height'
        initialSnap={1}
        onClose={handleclose}
        onDrag={handleclose}
        onSnap={snapIndex =>
          console.log('> Current snap point index:', snapIndex)
        } isOpen={bottomsheet}>
        <Sheet.Container className='!bg-primary' id='BottomSheet'>
          <Sheet.Header />
          <Sheet.Content><Bottomsheet /></Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}