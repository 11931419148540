import React from 'react'

const ReciterlistSkeleton = () => {
    return (
        <div className="flex flex-col md:flex-row">
            <div className="reciterlist before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_1s_infinite] before:border-t before:border-gray-900 before:bg-gradient-to-r before:from-transparent before:via-gray-900 before:to-transparent w-full px-2">
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-10 mb-8 mt-4 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
                <div className="bg-gray-200 animate-pulse my-2.5 rounded border border-gray-300 transition-all duration-300 h-16 md:mx-5"></div>
            </div>
        </div>
    )
}

export default ReciterlistSkeleton