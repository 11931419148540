import React, { useEffect, useState, useMemo, useRef, Suspense, lazy } from 'react';
import useScrollToTop from "../hooks/useScrollToTop";
import SearchLyrics from "../pages/SearchLyrics";
import { useDispatch, useSelector } from 'react-redux';
import { fetchNauhaData, fetchSozData } from '../redux/reducers/categoriesSlice';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from "../components/common/Footer";
import ReciterlistSkeleton from '../components/skeletons/ReciterlistSkeleton';

// Lazy load ReciterList component
const ReciterList = lazy(() => import('../components/common/ReciterList'));

const Soz = () => {
  const [inputTitle, setInputTitle] = useState('');
  const [visibleTitles, setVisibleTitles] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [page, setPage] = useState(1); // Current page of data
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { soz, loading } = useSelector(state => state.categories);
  const sentinelRef = useRef(null);

  useScrollToTop(true);

  const handleReciterSearch = (e) => {
    setInputTitle(e.target.value);
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  };

  const filterTitles = useMemo(() => {
    return (inputTitle) => {
      const escapedInputTitle = escapeRegExp(inputTitle.trim());
      const regex = new RegExp(escapedInputTitle, 'i'); // 'i' flag for case-insensitive match
      const filtered = soz.filter((title) => regex.test(title));
      setVisibleTitles(filtered);
    };
  }, [soz]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchSozData(page)); // Fetch data for the current page
      // Force an additional update to ensure data is rendered correctly
      setForceUpdate(prev => !prev);
    };
    fetchData();
  }, [dispatch, page]);

  useEffect(() => {
    filterTitles(inputTitle);
  }, [inputTitle, filterTitles, forceUpdate]);

  // Function to load more data when scrolling to the bottom
  const loadMoreData = () => {
    setPage(page + 1); // Increment page number to load next set of data
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bayaaz - Nauha</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Bayaaz Nauha here you got all the nauha lyrics"></meta>
      </Helmet>
      <section>
        <div className="container mx-auto h-fit">
          <div className="flex container mx-auto overflow-auto h-full flex-col md:flex-row">
            <Suspense fallback={<ReciterlistSkeleton />}>
              <ReciterList
                loading={loading}
                nauha={soz}
                inputTitle={inputTitle}
                setInputTitle={setInputTitle}
                filterTitles={filterTitles}
                visibleTitles={visibleTitles}
                pathname={pathname}
                handleReciterSearch={handleReciterSearch}
                sentinelRef={sentinelRef}
                onLoadMore={loadMoreData} // Pass the function to load more data
              />
            </Suspense>
            <div className="w-1/3 hidden md:block">
              <SearchLyrics />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Soz;
