import React, { useState } from 'react'
import Typograph from "../common/Typograph";
import ClipboardJS from 'clipboard';
import logo from "../../assets/images/Group 1072.svg"
import toast from 'react-hot-toast';
import Bismillah from "../../assets/Bismillah"
import { useNavigate } from 'react-router-dom';
const MainContainer = ({ title, description,gobackuri }) => {

  const navigate =  useNavigate()
  const [textsize, settextsize] = useState(2)
  const [fontbold, setfontbold] = useState(false)
  const textarray = ["text-sm", "text-base", "text-lg", "text-xl", "text-2xl", "text-3xl", "text-4xl", "text-5xl", "text-6xl", "text-7xl"]

  const handlecopy = () => {
    var clipboard = new ClipboardJS('.selecttext');

    clipboard.on('success', function (e) {
      toast.remove();
      toast.success('Copied Successfully!')
      e.clearSelection();
    });

    clipboard.on('error', function (e) {
      console.error('Action:', e.action);
      console.error('Trigger:', e.trigger);
    });
  }


  const handletextplus = () => {
    if (textsize < 9) {
      settextsize(textsize + 1);
    }
  }

  const handletextminus = () => {
    if (textsize > 0) {
      settextsize(textsize - 1);
    }
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Bayaaz',
          text: description,
        });
      } catch (error) {
        console.error('Error sharing text:', error.message);
      }
    } else {
      console.warn('Web Share API not supported on this browser.');
    }
  };

  return (
    <div className="h-auto md:px-4 relative">
      <h2 className="text-primary text-lg px-3 md:px-4 py-5 flex items-center">
        <button className='md:hidden' onClick={()=>navigate(gobackuri)}>
          <i className="bi bi-arrow-left-short mr-2 text-2xl"></i>
        </button>
        <span className='overflow-ellipsis overflow-hidden font-medium whitespace-nowrap'>{title}</span>
      </h2>
      <div className="bg-secondary w-full h-auto border rounded-t-3xl md:rounded-b-3xl text-primary">
        <div className="flex justify-between items-center md:px-5">
          <div className="space-x-3 mt-4 ml-3">
            <button onClick={handletextminus}>
              <i className="bi bi-dash-square-fill text-xl"></i>
            </button>
            <button onClick={() => settextsize(2)}>
              <i className="bi bi-type text-2xl font-bold"></i>
            </button>
            <button onClick={handletextplus}>
              <i className="bi bi-plus-square-fill text-xl"></i>
            </button>
          </div>
          <div className="space-x-2 mt-6 mr-2">
            <button onClick={() => setfontbold(!fontbold)}>
              <i className="bi bi-type-bold border border-primary rounded-full p-2 text-xl font-bold inline-flex items-center justify-center"></i>
            </button>
            <button onClick={handlecopy} className="selecttext" data-clipboard-text={description}>
              <i className="bi bi-clipboard2 border border-primary rounded-full p-2 text-lg inline-flex items-center justify-center"></i>
            </button>
            {/* <button onClick={handleShare}>
              <i className="bi bi-share border border-primary rounded-full p-2 text-lg inline-flex items-center justify-center"></i>
            </button> */}
            {/* <i className="bi bi-bookmark-heart border border-primary rounded-full p-2 text-lg inline-flex items-center justify-center"></i> */}
          </div>
        </div>
        <div className="flex items-center justify-center my-4">
          <Bismillah />
        </div>
        <div className={`${textarray[textsize]} ${fontbold ? "font-semibold" : ""} px-3 md:px-5 md:pb-5 pb-24`}>
          <Typograph text={description} />
        </div>
      </div>
    </div>
  )
}

export default MainContainer