import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { togglebottomsheet } from '../../redux/reducers/toggleSlice'

const BottomNav = () => {
    const location = useLocation()
    const { pathname } = location
    const dispatch = useDispatch()
    const links = ["/nauha", "/soz", "/manqabat", "/shayari"]

    const { bottomsheet } = useSelector((state) => state.toggle)

    const handlebottomsheet = () => {
        dispatch(togglebottomsheet(!bottomsheet))
    }

    return (
        <section id="bottom-navigation" className={`flex px-2 items-center flex-col justify-center w-full md:hidden bg-secondary border-t border-primary fixed inset-x-0 bottom-0 z-10 shadow ${bottomsheet ? "" : ""}`}>

            <div id="tabs" className={`flex w-full items-center  justify-between ${bottomsheet ? " border-primary" : "border-transparent"}`}>
                <Link to={"/"} className="w-full justify-center inline-block text-center pt-2.5">
                    <i className={`bi bi-${pathname === "/" ? "house-door-fill" : "house-door"} text-2xl pb-4 max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm rounded-t px-3 py-0.5 ${pathname === "/" ? "text-primary" : ""}`}></i>
                    {/* <span className={`tab tab-home block text-sm max-[360px]:text-xs max-[300px]:text-[0.6rem] max-[300px]:font-medium max-[300px] ${pathname === "/" ? "text-primary font-medium" : ""}`}>Home</span> */}
                    {
                        pathname === "/" ? <div className={`h-2 bg-primary mx-auto mt-2 rounded ${pathname === "/" ? "animate-width w-10" : ""}`}></div> : null
                    }
                </Link>
                <Link to={"/anjuman"} className="w-full justify-center inline-block text-center pt-2.5">
                    <i className={`bi bi-${pathname === "/anjuman" ? "people-fill" : "people"} text-2xl pb-4 max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm px-3 py-0.5 rounded ${pathname === "/anjuman" ? "text-primary" : ""}`}></i>
                    {/* <span className={`tab tab-whishlist block text-sm max-[360px]:text-xs max-[300px]:text-[0.6rem] max-[300px]:font-medium max-[300px] py-1 pb-4 ${pathname === "/anjuman" ? "text-primary font-medium" : ""}`}>Anjuman</span> */}
                    {
                        pathname === "/anjuman" ? <div className={`h-2 bg-primary mx-auto mt-2 rounded-t ${pathname === "/anjuman" ? "animate-width w-10" : ""}`}></div> : null
                    }
                </Link>
                <button onClick={handlebottomsheet} to={"/"} className={`w-full justify-center inline-block text-center pt-2.5 ${bottomsheet ? "" : " "}`}>
                    <i className={`bi bi-${links.some(pattern => pathname.includes(pattern)) ? "grid-fill" : "grid"} text-2xl pb-4 max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm px-3 py-0.5 rounded  ${links.some(pattern => pathname.includes(pattern)) ? "text-primary" : ""}`}></i>
                    {/* <span className={`tab tab-explore block text-sm max-[360px]:text-xs max-[300px]:text-[0.6rem] max-[300px]:font-medium max-[300px] py-1 ${links.some(pattern => pathname.includes(pattern)) ? "text-primary font-medium" : ""}`}>Category</span> */}
                    {
                        links.some(pattern => pathname.includes(pattern)) ? <div className={`h-2 bg-primary mx-auto mt-2 rounded-t ${links.some(pattern => pathname.includes(pattern)) ? "animate-width w-10" : ""}`}></div> : null
                    }
                </button>
                <Link to={"/mybayaaz"} className="w-full justify-center inline-block text-center pt-2.5">
                    <i className={`bi bi-${pathname.match("/mybayaaz") ? "book-half" : "book"} text-2xl pb-4 max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm px-3 py-0.5 rounded ${pathname.match("/mybayaaz") ? "text-primary" : ""}`}></i>
                    {/* <span className={`tab tab-kategori block text-sm max-[360px]:text-xs max-[300px]:text-[0.6rem] max-[300px]:font-medium max-[300px] py-1 pb-4 ${pathname === "/mybayaaz" ? "text-primary font-medium" : ""}`}>My Bayaaz</span> */}
                    {
                        pathname.match("/mybayaaz") ? <div className={`h-2 bg-primary mx-auto mt-2 rounded-t ${pathname.match("/mybayaaz") ? "animate-width w-10" : ""}`}></div> : null
                    }
                </Link>
                <Link to={"/setting"} className="w-full justify-center inline-block text-center pt-2.5">
                    <i className={`bi bi-${pathname.match("/setting") ? "gear-fill" : "gear"} text-2xl pb-4 max-[360px]:text-xl max-[300px]:text-md max-[300px]:text-sm px-3 py-0.5 rounded ${pathname.match("/setting") ? "text-primary" : ""}`}></i>
                    {/* <span className={`tab tab-account block text-sm max-[360px]:text-xs max-[300px]:text-[0.6rem] max-[300px]:font-medium max-[300px] py-1 pb-4 ${pathname === "/setting" ? "text-primary font-medium" : ""}`}>Setting</span> */}
                    {
                        pathname.match("/setting") ? <div className={`h-2 bg-primary mx-auto mt-2 rounded-t ${pathname.match("/setting") ? "animate-width w-10" : ""}`}></div> : null
                    }
                </Link>
            </div>
        </section>
    )
}

export default BottomNav