import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    bottomsheet: false,
  };
  
  const toggleSlice = createSlice({
    name :"bottomsheet",
    initialState,
    reducers:{
      togglebottomsheet(state,action){
        state.bottomsheet = action.payload
      }
    }
  })
  
  export const {togglebottomsheet} = toggleSlice.actions;
  export default toggleSlice.reducer;
  