import React from 'react'
import { useNavigate } from 'react-router-dom'
import mirbhai from "../../assets/images/Mirhasan.jpeg"
import takallumbhai from "../../assets/images/MirTakallum.jpeg"
import nadeembhai from "../../assets/images/NadeemSarwar.jpg"
import sibtejaffer from "../../assets/images/sibtejaffer.jpg"

function CategorySectionDesktop() {
  const navigate = useNavigate()
  return (
    <div className="md:flex hidden justify-center items-center">
      <div className="2xl:mx-auto 2xl:container py-12 px-4 sm:px-6 xl:px-20 2xl:px-0 w-full">
        <div className="flex flex-col justify-center items-center space-y-10">
          <div className="flex flex-col justify-center items-center">
            <h1 data-aos="fade" data-aos-duration={1500} className="text-6xl xl:text-6xl my-10 font-semibold leading-7 xl:leading-9 text-primary dark:text-white">Categories</h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4  w-full">
            {/* Category 1 */}
            <div data-aos="fade" data-aos-duration={1500} className="relative group flex justify-center items-center h-full w-full">
              <img className="object-center object-cover h-full w-full" src={nadeembhai} alt="Nauha" />
              <button onClick={()=>{navigate("/nauha")}} className="dark:bg-gray-800 border border-primary dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Nauha</button>
              <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
            </div>

            {/* Category 2 */}
            <div data-aos="fade" data-aos-duration={1500} className="flex flex-col space-y-4 md:space-y-8 mt-4 md:mt-0">
              <div className="relative group flex justify-center items-center h-full w-full">
                <img className="object-center object-cover h-full w-full" src={sibtejaffer} alt="shoe-image" />
                <button onClick={()=>{navigate("/soz")}} className="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Marsiya</button>
                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
              </div>

              {/* Category 3 */}
              <div data-aos="fade" data-aos-duration={1500} className="relative group flex justify-center items-center h-full w-full">
                <img className="object-center object-cover h-full w-full" src={mirbhai} alt="manqabat" />
                <button onClick={()=>{navigate("/manqabat")}} className="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Manqabat</button>
                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
              </div>
            </div>

            {/* Category 4 - Hidden in LG View */}
            <div data-aos="fade" data-aos-duration={1500} className="relative group hidden lg:flex justify-center items-center h-full w-full">
              <img className="object-center object-cover h-full w-full" src={takallumbhai} alt="Shayari" />
              <button onClick={()=>{navigate("/shayari")}} className="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Shayari</button>
              <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
            </div>

            {/* Category 4 - Shown in MD View */}
            <div className="relative group flex justify-center items-center h-full w-full mt-4 md:hidden md:mt-8 lg:hidden">
              <img className="object-center object-cover h-full w-full hidden md:block" src={takallumbhai} alt="Shayari" />
              <img className="object-center object-cover h-full w-full md:hidden" src={takallumbhai} alt="olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2" />
              <button className="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Manqabat</button>
              <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
            </div>
          </div>

          {/* Category 4 - Shown in LG View */}
          <div className="relative group hidden md:flex justify-center items-center h-full w-full mt-4 md:mt-8 lg:hidden">
            <img className="object-center object-cover h-full w-full hidden md:block" src={takallumbhai} alt="shayari" />
            <img className="object-center object-cover h-full w-full sm:hidden" src={takallumbhai} alt="olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2" />
            <button className="dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white">Manqabat</button>
            <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategorySectionDesktop