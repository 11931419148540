import React, { useEffect, useState } from 'react'

const ScrollTopButton = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

  // Function to update scroll position
  const updateScrollPosition = () => {
    setScrollPosition(window.pageYOffset);
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', updateScrollPosition);

    // Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, []);

    return (
        <button className={`${scrollPosition > 300 ? "opacity-100" : "opacity-0"} transition-all duration-500`} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}><i class="bi bi-arrow-up-circle hover:bg-secondary hidden md:block text-5xl fixed z-50 bottom-5 right-5 rounded-full text-primary"></i></button>
    )
}

export default ScrollTopButton