import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Footer from '../components/common/Footer';


const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Please enter a valid email address.').required('Email is required.'),
      message: Yup.string().required('Message Field Cannot Be Empty.'),
    }),
    onSubmit: (values) => {
      // Perform form submission logic here
      console.log('Form submitted successfully:');
    },
  });
  return (
    <>
   <section className="relative">
   <div className="absolute inset-0 w-full bg-gray-300">
   <iframe title="map"
         width="100%"
         height="100%"
         frameborder="0"
         marginheight="0"
         marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.5107046408707!2d73.02010417395378!3d19.172883748926388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf13f6d1c053%3A0x3c5904763f04590c!2sSuhana%20-%20Shia%20Masjid%20%26%20Imambada!5e0!3m2!1sen!2sin!4v1708854020261!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
     </div>
        
        <div className="container px-5 py-24 mx-auto flex">
          <form
            className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="text-primary text-lg mb-1 font-medium title-font">Feedback</h2>
            <p className="leading-relaxed mb-5 text-primary capitalize">your feedback will help us to improve our app.</p>
            <div className="relative mb-4" id="email">
              <label htmlFor="email" className="leading-7 text-sm text-primary">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={`w-full bg-white rounded border border-gray-300 focus:border-primary text-base outline-none text-primary py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${
                  formik.touched.email && formik.errors.email ? 'border-red-500' : ''
                }`}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-red-500 absolute left-0 mt-1">{formik.errors.email}</p>
              )}
            </div>
            <div className="relative mt-2 mb-4" id="message">
              <label htmlFor="message" className="leading-7 text-sm text-primary">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className={`w-full bg-white rounded border border-gray-300 focus:border-primary h-32 text-base outline-none text-primary py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out ${
                  formik.touched.message && formik.errors.message ? 'border-red-500' : ''
                }`}
                {...formik.getFieldProps('message')}
              ></textarea>
              {formik.touched.message && formik.errors.message && (
                <p className="text-red-500 absolute left-0 mt-1">{formik.errors.message}</p>
              )}
            </div>
            <button
              type="submit"
              className="text-white bg-primary border-0  mt-5 py-2 px-6 focus:outline-none rounded text-lg"
            >
              Send Feedback
            </button>
          </form>
        </div>
      </section>
      <Footer/>
</>
  )
}

export default ContactUs