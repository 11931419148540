import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

const LyricsCard = ({ category, title, recitername, year }) => {
    const { category: pages } = useParams()
    const { pathname } = useLocation()
    let setpath = category
    if (!category) { setpath = pages }
    if (!pages) { setpath = pathname.replace("/", "") }
    
    return (
        <Link to={`/${setpath}/${recitername}/${recitername} - ${year}/${title}`} className="flex flex-col bg-gray-50 p-3 border rounded-md my-2">
            <h1 className='text-lg text-primary overflow-hidden whitespace-nowrap overflow-ellipsis'>{title}</h1>
            {/* <i className="bi bi-arrow-right-circle-fill ml-auto text-2xl"></i> */}
            <p>{recitername} - {year}</p>
        </Link>
    )
}

export default LyricsCard