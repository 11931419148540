import React from 'react';
import google from '../assets/images/google-pay-icon.png';
import qr from "../assets/images/qr.jpeg";

const DonateUs = () => {
  return (
    <div className='container mx-auto p-8 md:p-12 lg:p-18'>
      <h1 className='text-3xl md:text-4xl lg:text-5xl text-center mb-8 text-primary'>Thank You for Donating Us!</h1>
      <hr
  class="mt-8 mb-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
      <div className="flex items-center justify-center">
        <div className="max-w-md bg-white p-8 rounded-lg shadow-lg dark:bg-neutral-700">
          <div className='mx-auto mt-5'>
            {/* Google Pay SVG */}
            <img src={google} alt="Google Pay" className="w-16 h-14 mx-auto" />
          </div>

          {/* Content */}
          <p className="mt-4 text-lg md:text-xl text-center text-gray-600 dark:text-gray-400">
            Pay Only With Google Pay/UPI
          </p>

          {/* Image */}
          <img
            src={qr}
            alt="Payment"
            className="mt-6 mx-auto rounded-lg max-w-full h-auto" // Added max-w-full and h-auto for responsiveness
          />
        </div>
      </div>
    </div>
  );
}

export default DonateUs;
