import React, { useEffect, useState } from "react";
import MainContainer from "../components/ReaderPage/MainContainer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { extractYearsFromString } from "../utils";
import { Helmet } from "react-helmet-async";
import SearchLyrics from "./SearchLyrics";


export function ReaderPage() {
  const [loading, setloading] = useState(true)
  const { category, reciteryearnauha, title } = useParams()
  const decode = decodeURI(reciteryearnauha)
  const { name, year } = extractYearsFromString(decode)
  const [description, setdescription] = useState()
  const goback = `/${category}/${name}/${reciteryearnauha}`

  const fetchdiscription = async () => {
    // console.log(name, year, title)
    try {
      const { data } = await axios.post(`${category == "nauha" ? "https://bayaaz-backend-nauha.vercel.app/" : ""}${category == "soz" ? "https://byaaz-backend-soz.vercel.app/" : ""}${category == "manqabat" ? "https://byaaz-backend-manqabat.vercel.app/" : ""}get/reciteryeartitledescription`, { recitername: name, year: year, title: title })
      if (data) {
        const { description, title } = data
        setdescription(description)
        // console.log(description)
        setloading(false)
      }
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    setloading(true)
    fetchdiscription()
  }, [title])


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title className="">Bayaaz {category.charAt(0).toUpperCase() + category.slice(1)} - {title} By {reciteryearnauha}</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content={description}></meta>
      </Helmet>
      <section className="h-fit md:bg-white">
        <div className="md:container h-full mx-auto">
          <div className="flex h-full flex-col overflow-auto md:flex-row">
            {
              !loading && description ?
                <div className="md:w-4/6  w-full pb-5 md:pb-5">
                  <MainContainer title={title} gobackuri={goback} description={description} />
                </div> : <>
                  <div className="md:w-4/6 h-full w-full  md:m-5 overflow-hidden">
                    <h1 className="h-8 bg-gray-300 mx-5 md:mx-0 mt-5 md:mt-0 animate-pulse rounded"></h1>
                    <div className="bg-gray-300 animate-pulse h-full mt-4 rounded-3xl mb-8"></div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                    <div class="w-full my-4 animate-pulse mx-5 md:mx-auto">
                      <h1 class="h-2 bg-gray-300 rounded-lg w-52"></h1>

                      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg"></p>
                      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg"></p>
                    </div>
                  </div>
                </>
            }
            <div className="md:w-1/3 w-full hidden  md:block">
              {/* <div className="my-6 searchbar">
                <SearchBar />
              </div> */}
              <SearchLyrics />
            </div>
          </div>
        </div>

      </section>

    </>
  );
}
