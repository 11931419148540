import React from 'react'
import useDeviceType from "../../hooks/useDeviceType"
const CheckDevice = ({ children }) => {
    return (
        <>
            {
                useDeviceType() === "Mobile" ? "" : children
            }
        </>
    )
}

export default CheckDevice