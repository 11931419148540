import React from 'react'
import { useLocation } from 'react-router-dom';

const CheckBreadcrumb = ({children}) => {
    const location = useLocation();
    const { pathname } = location;
  return (
    <>
    {pathname == "/" ? "" : children}
</>
  )
}

export default CheckBreadcrumb