import React from 'react'
import { Link } from 'react-router-dom'

const CategorySection = () => {
    return (
        <section>
            {/* <h1 className='mx-5 my-4 text-lg font-medium'>Our Categories</h1> */}
            <div className="flex my-4 justify-center md:hidden items-center mx-3">
                <Link  to={"/nauha"} className='w-1/4 flex items-center justify-center bg-gray-100 rounded-md mx-2 p-2'>
                    <div>
                        Nauha
                    </div>
                </Link>
                <Link to={"/manqabat"} className='w-1/4 flex items-center justify-center bg-gray-100 rounded-md mx-2 p-2'>
                    <div>
                        Manqabat
                    </div>
                </Link>
                <Link to={"/soz"} className='w-1/4 flex items-center justify-center bg-gray-100 rounded-md mx-2 p-2'>
                    <div>
                        Soz
                    </div>
                </Link>
                <Link to={"/shayari"} className='w-1/4 flex items-center justify-center bg-gray-100 rounded-md mx-2 p-2'> 
                    <div>
                        Shayari
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default CategorySection