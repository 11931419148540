// SignUp.js
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function SignUp() {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter your name."),
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
      password: Yup.string().matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must be at least 8 characters and include at 1 special character."
      )
      
    }),
    onSubmit: (values) => {
      
      console.log("Form submitted successfully:", values);
    },
  });
  return (
    <>
      {/* website */}

      {/* Left Side Form */}
      <div className="md:flex justify-center items-center px-7 w-full min-h-screen my-5 hidden">
        <div className="py-4 px-8 rounded-md shadow-lg sm:p-8 h-fit bg-white text-primary">
          <h2 className="text-3xl text-center mt-8 border-gray-300 border-b font-semibold pb-5">
            SignUp
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-7 mt-10">
              <input
                type="text"
                id="name"
                {...formik.getFieldProps("name")}
                className={`block px-2.5 pb-2.5 pt-4 w-full text-md text-primary bg-transparent border-1 border ${
                  formik.touched.name && formik.errors.name
                    ? "border-red-500"
                    : "border-gray-400"
                } appearance-none focus:outline-none focus:ring-0 focus:border-primary`}
                placeholder="Name"
              />
              {formik.touched.name && formik.errors.name && (
                <p className="text-red-500 mt-1">{formik.errors.name}</p>
              )}
            </div>

            <div className="mb-7 mt-10">
              <input
                type="email"
                id="email"
                {...formik.getFieldProps("email")}
                className={`block px-2.5 pb-2.5 pt-4 w-full text-md text-primary bg-transparent border-1 border ${
                  formik.touched.email && formik.errors.email
                    ? "border-red-500"
                    : "border-gray-400"
                } appearance-none focus:outline-none focus:ring-0 focus:border-primary`}
                placeholder="Email Address"
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-red-500 mt-1">{formik.errors.email}</p>
              )}
            </div>

            <div className="mb-7 mt-10">
              <input
                type="password"
                id="password"
                {...formik.getFieldProps("password")}
                className={`block px-2.5 pb-2.5 pt-4 w-full text-md text-primary bg-transparent border-1 border ${
                  formik.touched.password && formik.errors.password
                    ? "border-red-500"
                    : "border-gray-400"
                } appearance-none focus:outline-none focus:ring-0 focus:border-primary`}
                placeholder="Password"
              />
              {formik.touched.password && formik.errors.password && (
                <p className="text-red-500 mt-1">{formik.errors.password}</p>
              )}
            </div>

            <input
              type="button"
              name="SignUp"
              value="Sign Up"
              className="uppercase w-full py-3 tracking-wider text-md rounded-md cursor-pointer font-semibold bg-primary text-secondary outline-none"
            />

            <div className="flex items-center w-full my-4">
              <hr className="w-full text-gray-400" />
              <p className="px-3 text-primary">OR</p>
              <hr className="w-full text-gray-400" />
            </div>

            <div className="flex justify-center my-4">
              <button
                aria-label="Login with Google"
                type="button"
                className="flex p-2 text-primary space-x-3 border rounded-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  className="w-7 h-7 fill-current"
                  viewBox="0 0 48 48"
                >
                  {/* Google SVG path data */}
                </svg>
                <p className="font-semibold text-lg">
                  Login with Google recommendation
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* phone */}
      <div className="md:h-auto bg-primary md:hidden">
  <div className="py-4 flex">
    <i className="bi bi-arrow-left ml-4 mr-6 text-secondary text-2xl cursor-pointer"></i>
    <div>
      <span className="text-secondary font-semibold py-5 text-xl">
        Sign Up
      </span>
      <p className="text-secondary text-lead text-lg mt-1">
        This is important to sync lyrics...
      </p>
    </div>
  </div>

  <div className="bg-secondary w-full h-screen border rounded-tl-3xl rounded-tr-3xl text-primary">
    <form className="mx-10 pt-5" onSubmit={formik.handleSubmit}>
      <div className="relative mb-7 mt-10">
        <input
          type="text"
          id="name"
          {...formik.getFieldProps("name")}
          className={`block px-2.5 pb-2.5 pt-4 w-full text-md text-primary bg-transparent border-1 border ${
            formik.touched.name && formik.errors.name
              ? "border-red-500"
              : "border-gray-400"
          } appearance-none focus:outline-none focus:ring-0 focus:border-primary peer`}
          placeholder=" "
        />
        <label
          htmlFor="name"
          className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-secondary px-2 peer-focus:bg-secondary peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
        >
          Name
        </label>
        {formik.touched.name && formik.errors.name && (
          <p className="text-red-500 absolute left-0 mt-1">
            {formik.errors.name}
          </p>
        )}
      </div>
      <div className="relative mb-7 mt-10">
        <input
          type="email"
          id="email"
          {...formik.getFieldProps("email")}
          className={`block px-2.5 pb-2.5 pt-4 w-full text-md text-primary bg-transparent border-1 border ${
            formik.touched.email && formik.errors.email
              ? "border-red-500"
              : "border-gray-400"
          } appearance-none focus:outline-none focus:ring-0 focus:border-primary peer`}
          placeholder=" "
        />
        <label
          htmlFor="email"
          className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-secondary px-2 peer-focus:bg-secondary peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
        >
          Email Address
        </label>
        {formik.touched.email && formik.errors.email && (
          <p className="text-red-500 absolute left-0 mt-1">
            {formik.errors.email}
          </p>
        )}
      </div>
      <div className="relative mb-7 mt-10">
        <input
          type="password"
          id="password"
          {...formik.getFieldProps("password")}
          className={`block px-2.5 pb-2.5 pt-4 w-full text-md text-primary bg-transparent border-1 border ${
            formik.touched.password && formik.errors.password
              ? "border-red-500"
              : "border-gray-400"
          } appearance-none focus:outline-none focus:ring-0 focus:border-primary peer`}
          placeholder=" "
        />
        <label
          htmlFor="password"
          className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-secondary px-2 peer-focus:bg-secondary peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
        >
          Password
        </label>
        {formik.touched.password && formik.errors.password && (
          <p className="text-red-500 absolute left-0 mt-1">
            {formik.errors.password}
          </p>
        )}
      </div>

      <input
        type="submit"
        name="SignUp"
        value="Sign Up"
        className="uppercase w-full py-3 tracking-wider text-md mt-3 rounded-md cursor-pointer font-semibold bg-primary text-secondary outline-none"
      />
    </form>
  </div>
</div>

    </>
  );
}
