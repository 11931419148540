import React from 'react'

const MobileFeature = () => {
  return (
    <>
      <section className='md:block hidden '>
        <h1  data-aos="fade-down" data-aos-duration={1500} className='text-5xl font-medium pt-12 text-primary text-center'>Why Choose Us ?</h1>
        <div className="container mx-auto">
          <div className=" w-full border-primary ">
            <div className="flex justify-between">
              {/* left */}
              <div className="flex flex-col gap-y-5 w-1/2 mr-auto justify-center items-center">
                <div  data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} className="border border-primary relative md:top-3 md:left-8 lg:top-0 lg:left-[46px] xl:left-[2.9rem] rounded-l-lg lg:p-3 md:py-1.5 lg:px-5 md:px-4 bg-secondary">
                  <div className="flex justify-between text-primary">
                    <h2 className="font-bold lg:text-lg md:text-lg">My Bayaaz</h2>
                    <i className="bi bi-book lg:text-xl md:text-lg"></i>
                  </div>
                  <p className="font-semibold text-primary lg:text-base text-sm">An additional feature to save your personal lyrics which are not available on app.</p>
                </div>

                <div  data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} className="border border-primary rounded-lg relative md:top-1.5 lg:top-0 lg:p-3 md:py-1.5 lg:px-5 md:px-3 bg-secondary">
                  <div className="flex justify-between text-primary">
                    <h2 className="font-bold lg:text-lg md:text-lg">Increased Performance </h2>
                    <i className="bi bi-graph-up-arrow lg:text-xl md:text-lg"></i>
                  </div>
                  <p className="font-semibold text-primary lg:text-base text-sm">Performance of the app has been increased tremendously.</p>
                </div>

                <div  data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} className="border border-primary relative md:top-0 md:left-8 lg:top-0  lg:left-[46px] xl:left-[2.9rem] rounded-l-lg lg:p-3 md:py-1.5 lg:px-5 md:px-3  bg-secondary">
                  <div className="flex justify-between text-primary">
                    <h2 className="font-bold lg:text-lg md:text-lg">Access on Web, iOS, and Androids</h2>
                    <i className="bi bi-feather lg:text-xl md:text-lg"></i>
                  </div>
                  <p className="font-semibold text-primary lg:text-base text-sm">Current We Are On Web Only , but we are available for Android and iOS (Coming Soon)</p>
                </div>
              </div>

              <div className="w-2/5 lg:h-fit"><img className='lg:h-1/2 md:h-full w-auto  relative z-10' src={require("../../assets/images/recentlyadded.png")} alt="" /></div>


              <div className="flex flex-col ml-auto items-center justify-center
               gap-y-5 w-1/2 relative">

                <div data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} className="border border-primary relative md:top-3 md:right-8 lg:top-0 lg:right-10 xl:right-[4.2rem] rounded-r-lg lg:p-3 md:py-1.5 lg:px-5 md:px-4 bg-secondary">
                  <div className="flex justify-between text-primary">
                    <h2 className="font-bold lg:text-lg md:text-lg">Send Lyrics</h2>
                    <i className="bi bi-send lg:text-xl md:text-lg"></i>
                  </div>
                  <p className="font-semibold lg:text-base text-sm text-primary">Now send your lyrics from within the app which is just an approval away from being live on the app</p>
                </div>

                <div data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} className="border border-primary rounded-lg relative md:top-1.5 lg:top-0 lg:p-3 md:py-1.5 lg:px-5 md:px-3 bg-secondary">
                  <div className="flex justify-between text-primary">
                    <h2 className="font-bold lg:text-lg md:text-lg">Edit Lyrics</h2>
                    <i className="bi bi-arrow-repeat lg:text-xl md:text-lg"></i>
                  </div>
                  <p className="font-semibold text-primary lg:text-base text-sm">Current We Are On Web Only , but we are available for Android and iOS (Coming Soon)</p>
                </div>

                <div data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} className="border border-primary relative md:top-0 md:right-6 lg:top-0 lg:right-10 xl:right-[5rem] rounded-r-lg lg:p-3 md:py-1.5 lg:px-5 md:px-3  bg-secondary">
                  <div className="flex justify-between text-primary">
                    <h2 className="font-bold lg:text-lg md:text-lg">Introduced Shayari Category</h2>
                    <i className="bi bi-megaphone lg:text-xl md:text-lg"></i>
                  </div>
                  <p className="font-semibold lg:text-base text-sm text-primary">Now Explore Shayari of the Specific Day, Ocassion, etc</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MobileFeature