import React from 'react'

const Typograph = ({text}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    
      return (
        <p id='customText' className='cursor-pointer select-none'>
          {formattedText}
        </p>
      );
    };

export default Typograph;