import React from 'react'
import Section1 from '../components/anjumancomponents/Section1'
import AnjumanCards from '../components/anjumancomponents/AnjumanCards'

const Anjuman = () => {
  return (
    <>
      {/* <Section1 /> */}
      <div className="flex bg-gray-100 h-full w-full items-center justify-center">
        <h1 className='text-4xl'>Coming Soon..</h1>
      </div>
      {/* <section>
      <div className="px-2 mx-auto container pb-20">
        <AnjumanCards/>
        <AnjumanCards/>
        <AnjumanCards/>
        <AnjumanCards/>
        <AnjumanCards/>
      </div>
      </section> */}
    </>
  )
}

export default Anjuman