import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import screen1 from "../../assets/images/WhatsApp Image 2024-02-23 at 17.25.06_8f6275d7.jpg"
import screen2 from "../../assets/images/WhatsApp Image 2024-02-23 at 17.25.07_2c0752b1.jpg"
import screen3 from "../../assets/images/WhatsApp Image 2024-02-23 at 17.25.07_4172046d.jpg"
import screen4 from "../../assets/images/WhatsApp Image 2024-02-23 at 17.25.07_f7be244b.jpg"
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, EffectCoverflow, FreeMode, Pagination } from 'swiper/modules';

const UniqueAppScreen = () => {
    return (
        <>
            <section className='py-5'>
            <h1  data-aos="fade" data-aos-duration={1500} className='my-5 text-5xl text-primary text-center font-medium'> Unique App Screen</h1>
            <p   data-aos="fade" data-aos-duration={1500}className='text-center'>App design showcase created with love for our users...</p>
                <Swiper
                    // effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={3}
                    // freeMode={true}
                    spaceBetween={30}
                    onActiveIndexChange
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    initialSlide={2}
                    modules={[EffectCoverflow, Pagination, Autoplay,FreeMode]}
                    className="swiper1 w-full h-1/2 px-24"
                >
                    <SwiperSlide >
                        <img src={screen1} className='rounded-2xl !h-1/2 border' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={screen2} className='rounded-2xl !h-1/2 border' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={screen3} className='rounded-2xl !h-1/2 border' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={screen4} className='rounded-2xl !h-1/2 border' />
                    </SwiperSlide>
                </Swiper>
            </section>
        </>
    );
}

export default UniqueAppScreen