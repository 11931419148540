import { useEffect } from 'react';

const useScrollToTop = (condition = true) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: smooth scrolling effect
      });
    };

    if (condition) {
      scrollToTop(); // Scroll to top when component mounts or condition is met
    }

    return () => {
      // Clean-up function (optional)
    };
  }, [condition]); // Dependency array ensures effect runs when condition changes
};

export default useScrollToTop;
