import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { extractYearsFromString } from '../utils'
import useScrollToTop from '../hooks/useScrollToTop'
import SearchLyrics from "../pages/SearchLyrics"
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

const ReciterYearTitle = () => {
    const navigate = useNavigate()
    const { reciteryearnauha, category } = useParams()
    const [loading, setloading] = useState(true)
    const { pathname } = useLocation()
    const [years, setyears] = useState()
    const { nauhaUrl, manqabatUrl, shayariUrl, sozUrl } = useSelector(state => state.urls);
    const { name, year } = extractYearsFromString(reciteryearnauha)
    const [url, seturl] = useState()

    const fetch = async () => {
        try {
            const { data } = await axios.post(`${url}get/reciteryeartitle`, { recitername: name, year: year })
            if (data) {
                const { titlelist } = data
                setyears(titlelist)
                setloading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (pathname.match("/nauha")) seturl(nauhaUrl)
        if (pathname.match("/manqabat")) seturl(manqabatUrl)
        if (pathname.match("/soz")) seturl(sozUrl)
    }, [])

    useEffect(() => {
        if (url) fetch()
    }, [url])

    useScrollToTop(true)
    return (
        <>
            {
                seturl &&
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title className="">Bayaaz {category.charAt(0).toUpperCase() + category.slice(1)} - {reciteryearnauha}</title>
                        <link rel="canonical" href="http://mysite.com/example" />
                        <meta name="description" content={reciteryearnauha}></meta>
                    </Helmet>
                    <section className='bg-white h-fit overflow-hidden'>
                        <div className="md:container mx-auto h-full">
                            <div className="flex overflow-auto flex-col h-full md:flex-row">
                                <div className="md:w-4/6 w-full md:pb-6 pb-16 hidescrollbar overflow-auto">
                                    <h1 className='md:mx-4 md:p-3 md:mt-3 text-lg font-medium flex justify-start items-center text-primary md:bg-white bg-secondary p-4 rounded-br-3xl'>
                                        <button className='md:hidden' onClick={() => navigate(`/${category}/${name}`)}>
                                            <i className="bi bi-arrow-left-short mr-2 text-2xl"></i>
                                        </button>
                                        <span>{reciteryearnauha}</span>
                                    </h1>
                                    <div className='p-4 md:p-0'>
                                        {
                                            years ?
                                                years.map((items, index) => {
                                                    return <div className='w-full'>
                                                        <Link key={index + "reciteryeartitle" + index} to={`/${category}/${name}/${reciteryearnauha}/${items}`} className='flex text-primary hover:scale-[0.992] transition-all duration-300 bg-secondary items-center rounded-md py-4 my-4 px-2 md:mx-2'>
                                                            <i className="bi bi-x-diamond-fill m-2"></i>
                                                            <span className='text-ellipsis overflow-hidden whitespace-nowrap'>{items}</span>
                                                            <i className="bi bi-chevron-right text-lg md:text-2xl ml-auto"></i>
                                                        </Link>
                                                    </div>
                                                }) : <>
                                                    <div className=" h-full w-full">
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                        <div className="bg-gray-300 animate-pulse my-4 h-14 md:mt-0 mt-4 mx-2 rounded-lg"></div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className="md:w-1/3 hidden hidescrollbar overflow-auto md:block w-full">
                                    <SearchLyrics />
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default ReciterYearTitle