import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

const DynamicBreadcrumb = () => {
    const location = useLocation();
    const paths = location.pathname.split('/').filter(path => path !== ''); // Split the pathname and filter out empty strings

    return (
        <div className="bg-secondary hidden md:block">
            <div className="container overflow-ellipsis flex items-center px-6 py-4 mx-auto  whitespace-nowrap">
                <Link to={"/"} className="text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                </Link>

                {paths.map((path, index) => (
                    <React.Fragment key={index}>
                        <span className="mx-5">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        </span>
                        <Link to={`/${paths.slice(0, index + 1).join('/')}`} className="text-black capitalize">
                            {decodeURIComponent(path)}
                        </Link>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default DynamicBreadcrumb;
