import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"

const Playstore = () => {
  return (
    <section className="py-6  text-gray-900">
    <div  className="container mx-auto flex flex-col justify-between p-4 text-center md:p-10 lg:flex-row">
      <div className="flex flex-col justify-start lg:text-left">
        <p  data-aos="fade-right" data-aos-duration={1500} className="mb-1 animate-pulse text-sm font-medium tracki uppercase text-primary">Download Size
          5 MB</p>
        <h1  data-aos="fade-right" data-aos-duration={1500} className="py-2 text-3xl font-medium leadi title-font">Experience It in Your Hands: Download Our App Now!</h1>
      </div>
      <motion.div whileTap={{scale:0.9}} className="flex flex-col items-center justify-center flex-shrink-0 mt-6 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:ml-4 lg:mt-0 lg:justify-end">
        <Link to={"https://play.google.com/store/apps/details?id=bayaaz.com"} target='_blank' data-aos="fade-up" data-aos-duration={1500} className="inline-flex items-center px-6 py-3 rounded-lg bg-secondary hover:bg-primary border !transition-all !duration-500 hover:border-primary hover:text-secondary text-primary">
          <i class="bi bi-google-play text-2xl"></i>
          <span className="flex flex-col items-start ml-4 leadi">
            <span className="mb-1 text-xs">GET IT ON</span>
            <span className="font-semibold title-font">Google Play</span>
          </span>
        </Link>
        {/* <button className="inline-flex items-center px-5 py-3 rounded-lg bg-primary text-gray-50">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className="fill-current w-7 h-7 text-gray-50">
                <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z"></path>
            </svg>
            <span className="flex flex-col items-start ml-4 leadi">
                <span className="mb-1 text-xs">Download on the</span>
                <span className="font-semibold title-font">App Store</span>
            </span>
        </button> */}

      </motion.div>
    </div>
  </section>
  )
}

export default Playstore