export function extractYearsFromString(inputString) {
  const yearRegex = /\b\d{4}\b/g; // Match any sequence of four digits
  const matches = inputString.match(yearRegex);
  const years = matches ? matches.map(match => parseInt(match)) : [];
  const slice = inputString.length - 6
  const name = inputString.slice(0,slice).trim()
  return {
    name: name,
    year: years[0]
  };
}
