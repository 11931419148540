import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const BottomSheet = () => {
    const location = useLocation()
    const { pathname } = location

    return (
        <div id='bottomsheet' className={`w-full mb-20 transition-all rounded-t-lg z-40 duration-1000`}>
            <div className='m-2 border border-secondary rounded-t-xl rounded-b'>
                <h1 className='text-center py-1 bg-secondary text-primary rounded-t-xl text-lg '>Categories</h1>
                <div className="grid grid-cols-2 gap-4 m-5">
                    <Link to={"/nauha"} className={`p-2 border bg-secondary text-primary text-center rounded-md ${pathname.match("/nauha") ? "animate-pulse" : ""}`}>Nauha</Link>
                    <Link to={"/soz"} className={`p-2 border bg-secondary text-primary text-center rounded-md ${pathname.match("/soz") ? "animate-pulse" : ""}`}>Soz</Link>
                    <Link to={"/manqabat"} className={`p-2 border bg-secondary text-primary text-center rounded-md ${pathname.match("/manqabat") ? "animate-pulse" : ""}`}>Manqabat</Link>
                    <Link to={"/shayari"} className={`p-2 border bg-secondary text-primary text-center rounded-md ${pathname.match("/shayari") ? "animate-pulse" : ""}`}>Shayari</Link>

                </div>
            </div>
        </div>
    )
}

export default BottomSheet