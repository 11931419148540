import {configureStore } from "@reduxjs/toolkit";
import categoriesSlice from "../reducers/categoriesSlice";
import toggleSlice from "../reducers/toggleSlice";
import urlSlice from "../reducers/urlSlice";

const store = configureStore({
    reducer:{
        toggle : toggleSlice,
        categories: categoriesSlice,
        urls: urlSlice,
    }
})

export default store;
