import React from 'react'
import { Link } from 'react-router-dom'

const Setting = () => {
  return (

    <>
      <div className="container mx-auto">

      <div className="header p-4 font-medium px-6 text-lg flex items-center text-primary bg-secondary">
          <i className="bi bi-gear-fill mr-4"></i>
          <h1 className=''>Setting</h1>
          <button className='ml-auto'>
            {/* <i className="bi bi-x-lg "></i> */}
          </button>
        </div>

        {/* children */}
        <div className="px-5">
          {/* <Link to={"/setting/profile"} class="flex items-center py-8 bg-gray-100 gap-x-2">
            <div  class="relative">
              <img class="object-cover w-16 h-16 rounded-full ring ring-gray-300" src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100" alt="" />
              <span class="absolute bottom-0 right-0 w-3.5 h-3.5 rounded-full bg-emerald-500 ring-1 ring-white"></span>
            </div>
            <div>
              <h1 class="text-xl font-semibold text-gray-700 capitalize">Mia John</h1>
              <p class="text-base text-gray-500">miajohn@merakiui.com</p>
            </div>
            <div className='ml-auto'>
              <i className="bi bi-chevron-right text-xl text-gray-500"></i>
            </div>
          </Link> */}

          <Link to={"/aboutus"} className='border-t border-gray-300 flex items-center py-4'>
            <i className="bi bi-info-circle-fill text-lg mr-2"></i>
            <h1 className='text-lg'>About Us</h1>
            <i className="bi bi-chevron-right text-lg ml-auto"></i>
          </Link>

          {/* <Link target='_blank' to={"https://play.google.com/store/apps/details?id=bayaaz.com"} className='border-t border-gray-300 flex items-center py-4'>
            <i className="bi bi-star-fill text-lg mr-2"></i>
            <h1 className='text-lg'>Rate Us</h1>
            <i className="bi bi-chevron-right text-lg ml-auto"></i>
          </Link>

          <Link to={"mailto:bayaaz.official@gmail.com"} target='_blank' className='border-t border-gray-300 flex items-center py-4'>
            <i class="bi bi-person-lines-fill text-lg mr-2"></i>
            <h1 className='text-lg'>Contact Us</h1>
            <i className="bi bi-chevron-right text-lg ml-auto"></i>
          </Link> */}

          <Link to={"/privacypolicy"} className='border-t border-gray-300 flex items-center py-4'>
            <i class="bi bi-shield-fill-check text-lg mr-2"></i>
            <h1 className='text-lg'>Privacy Policy</h1>
            <i className="bi bi-chevron-right text-lg ml-auto"></i>
          </Link>

        </div>
      </div>
    </>
  )
}

export default Setting