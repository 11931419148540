import React from 'react'
import { Link } from 'react-router-dom'
const RecentlyAddedSection = () => {
  return (
    <section className="md:hidden">
    <div className="container mx-auto pb-20">
      <div className="card-container border">
        <div className="header">
          <h1 className="text-lg font-medium mx-6 md:mx-0 my-4">Recently Added Lyrics</h1>
        </div>

        <Link to={"/nauha/Nadeem%20Sarwar/Nadeem%20Sarwar%20-%202023/Hoga%20Ya%20Hussain"}>
        <div className="card p-6 m-4  relative rounded-lg bg-secondary text-primary border">
          <i className="bi bi-chevron-right absolute right-6 top-6"></i>
          <h1 className='text-lg font-medium'>Hoga Ya Husain</h1>
          <p className='mb-4'>
            Nadeem Sarwar - 2022
          </p>
          <span className='bg-black text-white mt-4 rounded py-2 px-4'>Nauha</span>
        </div>
        </Link>
      
      <Link to={"/manqabat/Shadman%20Raza/Shadman%20Raza%20-%202011/Imam%20e%20zamana%20ka%20ashiq%20bana%20de"}>
        <div className="card p-6 m-4  relative rounded-lg bg-secondary text-primary border">
          <i className="bi bi-chevron-right absolute right-6 top-6"></i>
          <h1 className='text-lg font-medium capitalize'>Imam E Zamana ka aashiq banade</h1>
          <p className='mb-4'>
            Shadman Raza - 2011
          </p>
          <span className='bg-red-800 text-white mt-4 rounded py-2 px-4'>Manqabat</span>
        </div>
      </Link>

      <Link to={"/manqabat/Miscellaneous/Miscellaneous%20-%202014/Imame%20Zamana"}>
        <div className="card p-6 m-4  relative rounded-lg bg-secondary text-primary border">
          <i className="bi bi-chevron-right absolute right-6 top-6"></i>
          <h1 className='text-lg font-medium capitalize'>Imame Zamana</h1>
          <p className='mb-4'>
            Miscellaneous - 2014
          </p>
          <span className='bg-red-800 text-white mt-4 rounded py-2 px-4'>Manqabat</span>
        </div>
      </Link>

      <Link to={"/nauha/Nadeem%20Sarwar/Nadeem%20Sarwar%20-%202023/%20Janum%20Ali%20Ali"}>
        <div className="card p-6 m-4  relative rounded-lg bg-secondary text-primary border">
          <i className="bi bi-chevron-right absolute right-6 top-6"></i>
          <h1 className='text-lg font-medium'>Janum Ali Ali</h1>
          <p className='mb-4'>
            Nadeem Sarwar - 2023
          </p>
          <span className='bg-black text-white mt-4 rounded py-2 px-4'>Nauha</span>
        </div>
        </Link>
      <Link to={"/manqabat/Mir%20Hasan%20Mir/Mir%20Hasan%20Mir%20-%202024/Likh%20Fatema%20Zahra%20Ki%20Sana"}>
        <div className="card p-6 m-4  relative rounded-lg bg-secondary text-primary border">
          <i className="bi bi-chevron-right absolute right-6 top-6"></i>
          <h1 className='text-lg font-medium capitalize'>Likh Fatema Zahra Ki Sana</h1>
          <p className='mb-4'>
            Mir Hasan Mir - 2024
          </p>
          <span className='bg-red-800 text-white mt-4 rounded py-2 px-4'>Manqabat</span>
        </div>
      </Link>

    

      </div>
    </div>
  </section>
  )
}

export default RecentlyAddedSection