import React from 'react'
import useOnlineStatus from '../../hooks/useOnlineStatus';

const CheckOnline = ({ children }) => {
    const isOnline = useOnlineStatus();
    return (
        <>
            {
                isOnline ? children : "offfline"
            }
        </>
    )
}

export default CheckOnline