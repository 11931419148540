import BottomNav from "../components/common/BottomNav";
import Home from "../pages/Home";
import "../styles/App.css"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Mybayaaz from "../pages/MyBayaaz"
import AboutUs from "../pages/AboutUs";
import Profile from "../pages/Profile"
import DonateUs from "../pages/DonateUs"
import Nauha from "../pages/Nauha";
import Soz from "../pages/Soz";
import Manqabat from "../pages/Manqabat";
import ContactUs from "../pages/ContactUs";
import Shayari from "../pages/Shayari";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Navbar from "../components/common/Header"
import Notifications from '../pages/Notifications'
import useDisableF12 from "../hooks/useDisableF12";
import useDisableRightClick from "../hooks/useDisableRightClick ";
import { ReaderPage } from "../pages/ReaderPage";
import CheckBottomSheet from "../components/common/CheckBottomSheet";
import DynamicBreadcrumb from "../components/common/Breadcrumb";
import CheckBreadcrumb from "../components/common/CheckBreadcrumb";
import ReciterYearTitle from "../pages/ReciterYearTitle";
import { Toaster } from "react-hot-toast";
import BottomSheetMain from "../components/common/BottomSheetMain";
import MobileHeader from "../components/common/MobileHeader";
import SearchLyrics from "../pages/SearchLyrics";
import CheckOnline from "../components/common/CheckOnline";
import AddNauha from "../pages/AddNauha";
import Anjuman from "../pages/Anjuman";
import Setting from "../pages/Setting";
import NauhaList from "../pages/NauhaList";
import Aos from "aos";
import ScrollTopButton from "../components/common/ScrollTopButton";
import ProtectRouteMobile from "../components/common/ProtectRouteMobile";
import ErrorPage from "../pages/ErrorPage";

function App() {
  // useDisableRightClick()
  // useDisableF12()
  Aos.init()
  return (
    <>
      <CheckOnline>
        <Router>
          <Navbar />
          <CheckBreadcrumb>
            <DynamicBreadcrumb />
          </CheckBreadcrumb>
          <MobileHeader />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/mybayaaz" element={<ProtectRouteMobile><Mybayaaz /></ProtectRouteMobile>} />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route exact path="/anjuman" element={<ProtectRouteMobile><Anjuman /></ProtectRouteMobile>} />
            <Route exact path="/setting" element={<ProtectRouteMobile><Setting /></ProtectRouteMobile>} />
            <Route exact path="/setting/profile" element={<ProtectRouteMobile><Profile /></ProtectRouteMobile>} />
            <Route exact path="/donateus" element={<DonateUs />} />
            <Route exact path="/nauha" element={<Nauha />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route exact path="/soz" element={<Soz />} />
            <Route exact path="/manqabat" element={<Manqabat />} />
            <Route exact path="/shayari" element={<Shayari />} />
            <Route exact path="/login" element={<ProtectRouteMobile><Login /></ProtectRouteMobile>} />
            <Route exact path="/signup" element={<ProtectRouteMobile>< SignUp /></ProtectRouteMobile>} />
            <Route exact path="/mybayaaz/addnauha" element={<ProtectRouteMobile>< AddNauha /></ProtectRouteMobile>} />
            <Route exact path="/notification" element={<ProtectRouteMobile>< Notifications /></ProtectRouteMobile>} />
            <Route exact path="/:category/:recitername/:reciteryearnauha/:title" element={< ReaderPage />} />
            <Route exact path="/:category/:recitername" element={< NauhaList />} />
            <Route exact path="/:category/search" element={< SearchLyrics />} />
            <Route exact path="/:category/:recitername/:reciteryearnauha" element={< ReciterYearTitle />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
          />
          <CheckBottomSheet><BottomNav /></CheckBottomSheet>
          <BottomSheetMain />
          <ScrollTopButton />
        </Router>
      </CheckOnline>
    </>
  );
}

export default App;
