import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const MobileHeader = () => {
  const location = useLocation()
  const { pathname } = location

  return (
    <>
      <header className={`py-4 px-6 sticky top-[4.5rem] z-30 border-b rounded-br-3xl bg-secondary md:hidden ${pathname == "/nauha" || pathname == "/soz" || pathname == "/manqabat" || pathname === "/anjuman" || pathname === "/mybayaaz" || pathname == "/shayari" ? "" : "hidden"}`}>
        <div className='flex justify-between items-center'>
          <h1 className='text-lg text-primary font-medium capitalize'>{pathname.replace("/", "")}</h1>
          <Link to={`${pathname}/search`} className={`${pathname == "/mybayaaz" || pathname == "/anjuman" ? "hidden" : ""}`}>
            <i className="bi bi-search text-lg"></i>
          </Link>
          <Link to={`${pathname}/addNauha`} className={`${pathname === "/mybayaaz" ? "flex" : "hidden"}  justify-center items-cente rounded active:scale-[0.98] hover:scale-[0.98] text-primary`}>
            {/* <span className='ml-3 text-sm'>Add Lyrics</span> */}
            <i className="bi bi-plus-circle text-xl"></i>
          </Link>
          {/* <Link to={`${pathname}/addNauha`} className={`${pathname === "/anjuman" ? "flex" : "hidden"} justify-center items-cente rounded active:scale-[0.98] hover:scale-[0.98] text-primary`}>
            <i className="bi bi-megaphone text-2xl"></i>
          </Link> */}
        </div>
      </header>
    </>
  )
}

export default MobileHeader