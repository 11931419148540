import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SearchLyrics from "./SearchLyrics";
import { useSelector } from "react-redux";
import useScrollToTop from "../hooks/useScrollToTop";
import { Helmet } from "react-helmet-async";

export default function NauhaList() {
  const [reciter, setreciter] = useState()
  const { recitername } = useParams()
  const [years, setyears] = useState()
  const [loading, setloading] = useState(true)
  const { pathname } = useLocation()
  const { category } = useParams()
  const [url, seturl] = useState()
  const navigate = useNavigate()
  const { nauhaUrl, manqabatUrl, shayariUrl, sozUrl } = useSelector(state => state.urls);


  const fetchYears = async () => {

    try {
      const { data } = await axios.post(`${url}get/reciteryears`, { recitername: reciter })
      if (data) {
        const { recitername, years } = data
        setreciter(recitername)
        setyears(years)
        // console.log(years)
        setloading(false)
        // console.log(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setreciter(recitername)
    if (pathname.match("/nauha")) seturl(nauhaUrl)
    if (pathname.match("/manqabat")) seturl(manqabatUrl)
    if (pathname.match("/soz")) seturl(sozUrl)
  }, [reciter])

  useEffect(() => {
    if (url) fetchYears()
  }, [url])

  useScrollToTop()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title className="">{`Bayaaz ${category.charAt(0).toUpperCase() + category.slice(1)} - ${reciter}`}</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content={""}></meta>
      </Helmet>
      <section className="h-fit">
        <div className="md:container mx-auto">
          <div className="flex overflow-auto h-full flex-col md:flex-row">

            <div className="md:w-4/6 w-full h-full pb-14 md:pb-5">
              <div className=" h-full ">
                <h1 className='md:mx-2 md:p-3 md:mt-3 text-lg font-medium flex justify-start items-center text-primary md:bg-white bg-secondary p-4 rounded-br-3xl'>
                  <button className='md:hidden' onClick={() => navigate(`/${category}`)}>
                    <i className="bi bi-arrow-left-short mr-2 text-2xl"></i>
                  </button>
                  <span>{recitername}</span>
                </h1>

                <div className="hidescrollbar md:border-none md:mt-0 overflow-auto pb-16 w-full h-full mt-5 text-primary">
                  {
                    !loading && years ?
                      years.map((item, index) => {
                        return <Link key={"yearcard" + index} to={`/${category}/${reciter}/${reciter} - ${item.year}`} className="flex text-primary hover:scale-[0.992] transition-all duration-300 bg-secondary m-4 items-center rounded-md py-4 px-2">
                          <i className="bi bi-x-diamond-fill ml-3"></i>
                          <span className="text-lg font-medium ml-3 mr-auto">{item.year}</span>
                          <i className="bi bi-chevron-right mr-3"></i>
                        </Link>
                      })
                      : <>
                        <div className=" h-full w-full">
                          <div className="bg-gray-300 animate-pulse h-14 md:mt-0 my-4 mx-4 rounded-lg"></div>
                          <div className="bg-gray-300 animate-pulse h-14 md:mt-0 my-4 mx-4 rounded-lg"></div>
                          <div className="bg-gray-300 animate-pulse h-14 md:mt-0 my-4 mx-4 rounded-lg"></div>
                          <div className="bg-gray-300 animate-pulse h-14 md:mt-0 my-4 mx-4 rounded-lg"></div>
                          <div className="bg-gray-300 animate-pulse h-14 md:mt-0 my-4 mx-4 rounded-lg"></div>
                          <div className="bg-gray-300 animate-pulse h-14 md:mt-0 my-4 mx-4 rounded-lg"></div>
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>

            <div className="md:w-1/3 w-full hidden  md:block">
              <SearchLyrics />
            </div>
          </div>
        </div>

      </section>
    </>
  );
}
